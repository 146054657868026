import Rainbow from 'rainbowvis.js';

export default function ColourMapping() {
  let colour=[]
  // let colour=['#7270BF', '#B689CE', '#9b68b6', '#7f489d', '#642785', '#f0cd84', '#ebc576', '#e5bd68', '#e0b45a', '#daac4c', '#d5a43e', '#cf9c30', '#ca9322', '#c48b14', '#bf8306', '#97ca86', '#93c682', '#8fc37e', '#8bbf7a', '#87bc75', '#83b871', '#7fb56d', '#7bb169', '#77ad65', '#73aa61', '#6fa65c', '#6ba358', '#679f54', '#639b50', '#5f984c', '#5b9448', '#579143', '#538d3f', '#4f8a3b', '#4b8637', '#478233', '#437f2f', '#3f7b2a', '#3b7826', '#377422', '#33701e', '#2f6d1a', '#2b6916', '#276611', '#23620d', '#1f5f09', '#1b5b05', '#8BC2E0', '#EAAD75', '#7adcdc', '#51baba', '#299797', '#007575', '#ED8F8F', '#c04848', '#920000', '#E2B3CF', '#8B2A64']
let rainbow=new Rainbow()
// rainbow.setSpectrum('#7ADCDC', '#ED8F8F', '#97CA86', '#A6A5D7', '#B689CE', '#8BC2E0', '#E2B3CF', '#EAAD75',  '#F0CD84','#BA7EA2')
rainbow.setSpectrum('#9C65BA', '#D6AA52', '#3F792B', '#3377C7', '#CC803A', '#2F9090')
rainbow.setNumberRange(1,58)
for (var i = 1; i <= 58 ; i++) {
    var hex = '#' + rainbow.colourAt(i);
    colour.push(hex)}
  // console.log(colour)
  let tag=['0', '1', '1.1', '1.1.1', '1.1.2', '2', '2.1', '2.1.1', '2.1.2', '2.1.3', '2.1.3.1', '2.1.3.2', '2.1.3.2a', '2.1.3.2b', '2.1.3.3', '2.2', '2.2.1', '2.2.1.1', '2.2.1.1a', '2.2.1.2', '2.2.2', '2.2.2.1', '2.3', '2.3.1', '2.3.2', '2.3.2.1', '2.3.2.1a', '2.3.2.1b', '2.3.2.1c', '2.3.2.1d', '2.3.2.1e', '2.3.2.1f', '2.3.2.1g', '2.3.3', '2.3.4', '2.3.4.1', '2.3.4.2', '2.3.4.3', '2.3.4.4', '2.3.4.4a', '2.3.4.4b', '2.3.4.4c', '2.3.4.4d', '2.3.4.4e', '2.3.4.4f', '2.3.4.4g', '2.3.4.4h', '2.4', '2.5', '3', '4', '5', '6', '7', '7.1', '7.2', '8', '9']
  let mappedColour={}
  for(let i=0; i<58; i++){
      mappedColour[tag[i]]=colour[i]
  }
  mappedColour.Query = "black"
  mappedColour.innode = "black"
  // console.log(mappedColour)
  return null
}
