import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import ExternalHeader from "../ExternalHeader";
import processing from "../../icons/processing.png";
import finished from "../../icons/finished.png";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import { BACKENDURL } from "../../contants/backendSettings";
import axios from "axios";
import history from "../history";
import useTabTitle from "../../utils/useTabTitle";
import { connect } from "react-redux";
import jwtAuthentication from "../../utils/jwtAuthentication"


const ProcessTask = (props) => {
  useTabTitle("TIPars | Task");
  const { token } = useParams();
  const [dateState, setDateState] = React.useState(new Date());
  const [data, setData] = React.useState({
    with_past_ml: false,
    project_name : null,
    email: null,
    query_seq_file_name: null,
    tree_file_name: null,
    taxa_seq_file_name: null,
    anc_seq_file_name: null,
    status: null,
    submitTime: null,
    costTime: null,
  });
  const [error, setError] = React.useState({
    state: false,
    message: null,
  });

  const fetchData = async (token, accessToken = null) => {
    try {
      let fetchMeta = {}
      if (accessToken) {
        fetchMeta = {
          headers : {
            Authorization: `Bearer ${accessToken}`
          }
        }
      }
      const response = await axios.get(`${BACKENDURL}/jobs/${token}/`, fetchMeta)
      if (!(response.status === 200 || response.status === 201)) {
        throw new Error("The token doesn't exist")
      }
      return {state: true, response : response}
    } catch (err) {
      return {state : false , response : undefined}
    }
  };
  
  const coreFetchData = async () => {
      const functionResponse = await jwtAuthentication((accessToken) => fetchData(token, accessToken), props.token, props.isSignedIn, props.refreshToken);
      const {state, response } = functionResponse
      if (!state) {
        setError({
          state: true,
          message: "The token doesn't exist",
        });
      } else {
          const {algo_type,
            created, duration,
              expiry_date, 
              finished, 
              id,
              placement_mode, 
              started, 
              status, 
              user, 
              with_past_ml,
              project_name,
              email,
              query_seq_file_name,
              tree_file_name,
              taxa_seq_file_name,
              anc_seq_file_name
            } = response.data
          setData({
            ...data,
            with_past_ml,
            project_name,
            email,
            query_seq_file_name,
            tree_file_name,
            taxa_seq_file_name,
            anc_seq_file_name,
            status: status,
            submitTime: created,
            costTime: duration,
          })
      }
  }

  React.useEffect(() => {
      // if (!props.isSignedIn) history.push("/")
      coreFetchData()
      const interval1 = setInterval(() => setDateState(new Date()), 1000);
      const interval2 = setInterval(coreFetchData, 5000)
    return () => {
      clearInterval(interval1)
      clearInterval(interval2)
    };
  }, []);

  // React,useEffect(document.location.reload(),[response])

  let resultButtonStatus;
  let iconStatus;
  let interruptDisplayForCancel;
  let interruptDisplayForResult;
  let disabled;

  if (data.status === "SUCCESS") {
    resultButtonStatus = "primary-button";
    iconStatus = finished;
    interruptDisplayForCancel = "none";
    interruptDisplayForResult = "auto";
    disabled = false;
  } else if (data.status === "CANCELLED") {
    resultButtonStatus = "disabled-button";
    iconStatus = finished;
    interruptDisplayForCancel = "none";
    interruptDisplayForResult = "none";
    disabled = true;
  } else {
    resultButtonStatus = "disabled-button";
    iconStatus = processing;
    interruptDisplayForCancel = "auto";
    interruptDisplayForResult = "auto";
    disabled = true;
  }
    
    // setTimeout(() => {
    //   if (iconStatus!==processing) {
    //     document.location.reload();}
    // }, 5000) ;

  const [open, setOpen] = React.useState(false);
  const handleInterruptOpen = () => {
    setOpen(true);
  };
  const handleInterruptClose = () => {
    setOpen(false);
  };
  const fetchDataInterrupt  = async (accessToken = null) => {
    let fetchMeta = {}
    if (accessToken) {
      fetchMeta = {
        headers : {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    try {
      const response = await axios.post(`${BACKENDURL}/jobs/cancel/${token}/`, {}, fetchMeta)
      if (response.status !== 200) {
          throw new Error("something went wrong when trying to cancel job")
        }
      return {state : true, response : response}
      } catch (err) {
        // console.log(err.message)
        return {state : false, response : undefined}
      }
    }

  // 
  const onInterrupt = (e) => {
    (async function() {
      const functionResponse = await jwtAuthentication((accessToken) => fetchDataInterrupt(accessToken),props.token, props.isSignedIn, props.refreshToken)
      const {state, response} = functionResponse
      if (state) {
        const {status} = response.data
        setData({...data, status});
      }
    })()
    handleInterruptClose()
    // (async function() {
    //   try {
    //     const response = await axios.post(`${BACKENDURL}/jobs/cancel/${token}/`)
    //     if (response.status !== 200) {
    //       throw new Error("Something went wrong when trying to cancel the job")
    //     }
    //     const {status} = response.data
    //       // do some stuff on frontend and change the UIUX
    //     setData({...data, status});
        
    //   } catch (e) {
    //     console.log(e.message)
    //   }
    // })()
    // handleInterruptClose()
  };

  const handleOnClick = (e) => {
    // console.log("(ProcessTask.js) handleOnClick", token);
    e.preventDefault();
    if (token) {
      // console.log("This is TaskCard Info: ", token);
      history.push(`/result/${token}`);
    } else {
    }
  };

  if (!error.state) {
    return (
      <div>
        <ExternalHeader />
        <Grid container>
          <Grid item sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="task-content">
              <div className="time-container">
                <p>
                  Submitted at{" "}
                  <span>
                    {data.submitTime
                      ? jobTimeConvertToStr(data.submitTime)
                      : null}
                  </span>
                </p>
                <p>
                  Time:&nbsp;
                  <span id="costTime">
                    {data.submitTime
                      ? jobTimeDeltaConvertToStr(
                          data.costTime,
                          dateState,
                          data.submitTime,
                          data.status
                        )
                      : "--:--:--"}
                  </span>
                </p>
              </div>
              <div className="model-status-container">
                <h3>{data.project_name ? data.project_name : "NA"}</h3>
                <div className="status-container">
                  <img src={iconStatus} alt="" />
                  <p>{data.status ? data.status : "NA"}</p>
                </div>
              </div>
              <div className="id-file-result-container">
              <div className="id-file-coantiner">
                <p>
                  Query Sequence:
                  <span>{data.query_seq_file_name ? data.query_seq_file_name : "NA"}</span>
                </p>
                <p>
                  Reference Tree:
                  <span>{data.tree_file_name ? data.tree_file_name: "NA"}</span>
                </p>
                <p>
                  Taxa MSA:
                  <span>{data.taxa_seq_file_name? data.taxa_seq_file_name: "NA"}</span>
                </p>
                {!data.with_past_ml ? (<p>
                  Ancestral Sequences Alignment:
                  <span>{data.anc_seq_file_name}</span>
                </p>) : null}
              </div>
                              <div className="result-button-container">
                              <button
                                className="dangerous-button"
                                style={{
                                  marginTop: "0",
                                  marginRight: "20px",
                                  display: interruptDisplayForCancel,
                                  marginTop: "36px"
                                }}
                                onClick={handleInterruptOpen}
                              >
                                Cancel
                              </button>
                              <button
                                className={resultButtonStatus}
                                style={{
                                  margin: "0",
                                  display : interruptDisplayForResult,
                                  marginTop: "36px"
                                }}
                                onClick={handleOnClick}
                                disabled={disabled}
                              >
                                Result
                              </button>
                            </div>
            </div>
            </div>
            <p className="task-bottom-noti">
              You can reload this page but please do <span>not</span> close or jump from this page during
              processing.
            </p>
          </Grid>
          <Grid item sm={2} xs></Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleInterruptClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure to interrupt the task?"}
          </DialogTitle>
          <DialogContent>
            <p>
              Interruption is irreversible and you will lose all records of this
              task.
            </p>
          </DialogContent>
          <DialogActions>
            <button
              className="secondary-button"
              onClick={handleInterruptClose}
              style={{
                marginRight: "10px",
              }}
            >
              Back
            </button>
            <button className="primary-button" onClick={onInterrupt} autoFocus>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <p>{error.message}</p>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(ProcessTask);
