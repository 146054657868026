import React from "react";
import Grid from "@material-ui/core/Grid";
import "./signin/FormSignin.css";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { useForm } from "react-hook-form";
import history from "./history";
import * as DisplayedMessages from "./DisplayedMessages";
import { BACKENDURL } from "../contants/backendSettings";
import { CircularProgress } from "@material-ui/core";
import useTabTitle from "../utils/useTabTitle";

const ForgetPassword1 = () => {
  useTabTitle("TIPars | Forget Password");
  // const [isSubmitted, setIsSubmitted] = useState(false);

  // function submitForm() {
  //   setIsSubmitted(true);
  // }
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const response = await fetch(`${BACKENDURL}/users/forget_password`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: data.email,
        }),
      }).then((res) => res.json());
      // console.log(response);
      if (response.error) {
        if (response.error === "This account does not exist.") {
          setError("email", {
            type: "matches",
            message: DisplayedMessages.ACCOUNT_NOT_EXIST,
          });
          throw new Error(DisplayedMessages.ACCOUNT_NOT_EXIST);
        } else if (
          response.error ===
          "Something is wrong when we are trying to send email."
        ) {
          throw new Error(DisplayedMessages.EMAIL_UNSEND);
        } else {
          history.push("/unclearError");
          throw new Error(DisplayedMessages.UNCLEAR_ERROR);
        }
      } else {
        setIsSubmitting(false);
        history.push("/forgetPassword2");
      }
    } catch (err) {
      if (err.message === "Failed to fetch") {
        history.push("/serverError");
        // console.log(DisplayedMessages.CONNECTION_ERROR);
      } else {
        // console.log(err.message);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div
      className="fgpw-container1"
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <HeaderWhite />
      <Grid item container>
        <Grid item sm={2} xs="auto"></Grid>
        <Grid item sm={8} xs={12}>
          <div className="fgpw-form-container">
            <h1
              style={{
                fontSize: "2rem",
                color: "black",
                letterSpacing: "1px",
                marginBottom: "40px",
              }}
            >
              Retrieve Password
            </h1>
            <div className="form-inputs">
              <form onSubmit={handleSubmit(onSubmit)} id="comfirmEmailForm">
                <label className="form-label"> E-mail Address </label>
                <input
                  className="form-input"
                  type="email"
                  placeholder="Enter your e-mail"
                  {...register("email", {
                    required: true,
                    maxLength: 255,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
                {errors.email?.type === "required" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.email?.type === "pattern" && (
                  <span style={{ color: "#F87700" }}>
                    {DisplayedMessages.EMAIL_FORMAT}
                  </span>
                )}
                {errors.email && (
                  <span style={{ color: "#F87700" }}>
                    {errors.email.message}
                  </span>
                )}
                <br />
                <button
                  className="form-input-btn"
                  type="submit"
                  form="comfirmEmailForm"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={24}
                      className="spinner"
                      color="#c4c4c4"
                      thickness={5}
                    />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </form>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} xs="auto"></Grid>
      </Grid>
    </div>
  );
};

export default ForgetPassword1;
