import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultHeader from "./ResultHeader";
import { Grid } from "@material-ui/core";
import Tree from "./tree/Tree.jsx";
import axios from "axios";
import { BACKENDURL } from "../../contants/backendSettings";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import fileDownload from "js-file-download";
import { cd2_relax as newickData } from "./tree/data";
import { connect } from "react-redux";
import history from "../history";
import jwtAuthentication from "../../utils/jwtAuthentication";
import mapToVisual from "../../utils/mapToVisual";
import ColourMapping from "./tree/ColourMapping";

const Result = (props) => {
  const { token } = useParams();
  const [dateState, setDateState] = React.useState(new Date());
  const [error, setError] = React.useState({
    state: false,
    message: null,
  });

  const [data, setData] = React.useState({
    with_past_ml: false,
    project_name: null,
    email: null,
    query_seq_file_name: null,
    tree_file_name: null,
    taxa_seq_file_name: null,
    anc_seq_file_name: null,
    result_text: null,
    status: null,
    submitTime: null,
    costTime: null,
    queries: null,
  });

  const [visualData, setVisualData] = React.useState(null);

  const fetchData = async (token, accessToken = null) => {
    let fetchMeta = {};
    if (accessToken) {
      fetchMeta = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    try {
      const response = await axios.get(
        `${BACKENDURL}/jobs/result/${token}/`,
        fetchMeta
      );
      if (!(response.status === 200 || response.status === 201)) {
        throw new Error(
          "something went wrong went fetching to backend or the token doesn't exist"
        );
      }
      return { state: true, response: response };
    } catch (err) {
      return { state: false, response: undefined };
    }
  };

  useEffect(() => {
    if (
      // !props.isSignedIn
      false
    ) {
      history.push("/");
    } else {
      (async function () {
        const functionResponse = await jwtAuthentication(
          (accessToken) => fetchData(token, accessToken),
          props.token,
          props.isSignedIn,
          props.refreshToken
        );
        const { state, response } = functionResponse;
        if (!state) {
          setError({
            state: true,
            message: "The token doesn't exist",
          });
        }
        // console.log("data", response.data)
        const {
          algo_type,
          created,
          duration,
          expiry_date,
          finished,
          id,
          placement_mode,
          started,
          status,
          user,
          with_past_ml,
          project_name,
          email,
          query_seq_file_name,
          tree_file_name,
          taxa_seq_file_name,
          anc_seq_file_name,
          result_text,
          queries,
        } = response.data;
        const preVisualData = mapToVisual(result_text, queries);
        setVisualData(preVisualData);

        setData({
          ...data,
          with_past_ml,
          project_name,
          email,
          query_seq_file_name,
          tree_file_name,
          taxa_seq_file_name,
          anc_seq_file_name,
          result_text,
          status: status,
          submitTime: created,
          costTime: duration,
          queries: queries,
        });
      })();
    }
  }, []);

  const fetchDataResultBinary = async (token, accessToken = null) => {
    let fetchMeta = {};
    if (accessToken) {
      fetchMeta = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }
    try {
      const response  = await axios.get(`${BACKENDURL}/jobs/result-binary/${token}/`, fetchMeta)
      return {state : true, response : response}
    } catch (err) {
      return { state: false, response: undefined };
    }
  };

  const onClickHandler = () => {
    (async function () {
      const functionResponse = await jwtAuthentication(
        (accessToken) => fetchDataResultBinary(token, accessToken),
        props.token,
        props.isSignedIn,
        props.refreshToken
      );
      const { state, response } = functionResponse;
      if (!state) return;
      fileDownload(response.data, `result_${token}.txt`);
    })()(async function () {
      try {
        const response = await axios.get(
          `${BACKENDURL}/jobs/result-binary/${token}`
        );
        console.log("response: ", response);
        fileDownload(response.data, `result_${token}.txt`);
      } catch (err) {
        console.log(
          "Something went wrong when fetching to the backend, please try again later"
        );
      }
    })();
  };

  return (
    <div>
      <ResultHeader />
      <ColourMapping />
      <Grid container>
        <Grid sm={2} xs></Grid>
        <Grid item sm={8} xs={12}>
          <div className="result-container">
            <div
              className="task-information-container"
              style={{
                borderBottom: "1px lightGrey solid",
              }}
            >
              <div className="time-container">
                <p>
                  Submitted at
                  <span>
                    {data.submitTime
                      ? ` ${jobTimeConvertToStr(data.submitTime)}`
                      : null}
                  </span>
                </p>
                <p>
                  Time:&nbsp;
                  <span>
                    {data.submitTime
                      ? `${jobTimeDeltaConvertToStr(
                          data.costTime,
                          dateState,
                          data.submitTime
                        )} `
                      : null}
                  </span>
                </p>
              </div>
              <div className="model-status-container">
                <h3>{data.project_name ? data.project_name : "NA"}</h3>
              </div>
              <div className="id-file-result-container">
                <div className="id-file-coantiner">
                  <p>
                    Query Sequence:&nbsp;
                    <span>
                      {data.query_seq_file_name
                        ? data.query_seq_file_name
                        : "NA"}
                    </span>
                  </p>

                  <p>
                    Reference Tree:&nbsp;
                    <span>
                      {data.tree_file_name ? data.tree_file_name : "NA"}
                    </span>
                  </p>
                  <p>
                    Taxa MSA:&nbsp;
                    <span>
                      {data.taxa_seq_file_name ? data.taxa_seq_file_name : "NA"}
                    </span>
                  </p>
                  {!data.with_past_ml ? (
                    <p>
                      Ancestral Sequence Alignment:&nbsp;
                      <span>
                        {data.anc_seq_file_name ? data.anc_seq_file_name : "NA"}
                      </span>
                    </p>
                  ) : null}
                  {/* ) : null} */}
                </div>
                <div className="result-button-container">
                  <button
                    className="primary-button"
                    // onClick={handleCilckOpen}
                    style={{
                      marginTop: "36px",
                    }}
                    onClick={onClickHandler}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {visualData ? (
        <Tree newickData={visualData} queries={data.queries} />
      ) : null}
      {/* <DrawCircle/> */}
      {/* <Tree newickData={newickData} /> */}
      <div className="tree"></div>
      {/* <div>{data.result_text ? <span>{data.result_text}</span> : null}</div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(Result);
