const mapToVisual = (data, query) => {
    let dataCopy  = data.slice()
    query.forEach((ele) => {
        let length = ele.length + 1
        let firstCharOccur = dataCopy.indexOf(ele)
        let firstPart = dataCopy.substring(0, firstCharOccur + length)
        let secondPart = dataCopy.substring(firstCharOccur + length)
        dataCopy = firstPart + "{Test}" + secondPart
    });
    return dataCopy
    // return dataCopy.replaceAll('"','').replaceAll(' ', '_')
}

export default mapToVisual