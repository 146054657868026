import React from "react";

import PhylotreeApplication from "./PhylotreeApplication.jsx";

// import "bootstrap/dist/css/bootstrap.min.css";
import useWindowDimensions from "../..//hooks/useWindowDimensions.js";

export default function Tree(props) {
  const { width } = useWindowDimensions();
  const newickData = props.newickData;
  const queries = props.queries;
  return (
    <div>
      <div className="mobile-tree-container">
        The phylogenetic tree currently not supported to display on mobile mode.
      </div>
      <div
        style={{
          maxWidth: "67%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        className="tree-container"
      >
        <PhylotreeApplication width={width} newickData={newickData} queries={queries}/>
      </div>
    </div>
  );
}

// ReactDOM.render(
//   <App />,
//   document.body.appendChild(document.createElement("div"))
// );
