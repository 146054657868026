import { SIGN_IN, SIGN_OUT, LOCAL_STOARGE, CHANGE_INFO } from "./types";
import history from "../components/history";

export const signIn =
    (platform, platformId, Id, name, token, email, refresh, location) =>
    (dispatch, getState) => {
        dispatch({
            type: SIGN_IN,
            payload: {
                platform: platform,
                platformId: platformId,
                Id: Id,
                name: name,
                token: token,
                email: email,
                refresh: refresh,
                location: location,
            },
        });

        history.push("/taskCenter");
    };

export const signOut = () => (dispatch, getState) => {
    dispatch({
        type: SIGN_OUT,
    });
    history.push("/");
};

export const LocalStorageBoolean = (boleanValue) => (dispatch, getState) => {
    dispatch({
        type: LOCAL_STOARGE,
        payload: boleanValue,
    });
};


export const ChangeInfo = (token) => (dispatch, getState) => {
    dispatch({
        type : CHANGE_INFO,
        payload : token
    })
}