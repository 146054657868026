import React from "react";
import { Link } from "react-router-dom";
import tired from "../img/tired.svg";

const TokenWrong = () => {
  return (
    <div
      tw-container
      style={{
        backgroundColor: "#98ACAF",
        height: "100vh",
        position: "absolute",
        width: "100%",
      }}
    >
      <div
        className="tw-paper"
        style={{
          width: "686px",
          height: "686px",
          margin: "auto",
          position: "relative",
          top: "50%",
          marginTop: "-343px",
          borderRadius: "2px",
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        }}
      >
        <img
          alt="tired"
          src={tired}
          style={{
            marginTop: "60px",
          }}
        />
        <h1
          style={{
            color: "black",
            fontSize: "2rem",
            letterSpacing: "1px",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          Oops! There is nothing in this page.
        </h1>
        <span>Please check the link and try again.</span>
        <div
          style={{
            marginTop: "50px",
          }}
        >
          <Link to={`/`}>&lt; Go to TIPars</Link>
        </div>
      </div>
    </div>
  );
};

export default TokenWrong;
