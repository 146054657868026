import React from "react";
import HeaderWhite from "../headerWhite/HeaderWhite";
import "../../styles/core.css";
import { Grid } from "@material-ui/core";
import TaskCard from "./TaskCard";
import dataFile from "./task.json";
import { connect } from "react-redux";
import axios from "axios";
import { BACKENDURL } from "../../contants/backendSettings";
import history from "../history";
import NoTask from "./NoTask";
import generateAccessToken from "../../utils/GenerateAccessToken";
import useTabTitle from "../../utils/useTabTitle";
import { CircularProgress } from "@material-ui/core";
import jwtAuthentication from "../../utils/jwtAuthentication";

const TaskCenter = (props) => {
  useTabTitle("TIPars | Task Center");
  const [data, setData] = React.useState([]);
  // const data = dataFile.message;
  const [isLoadingData, setIsLoadingData] = React.useState(false);


  const fetchData = async (userID, accessToken = null) => {
    let fetchMeta = {}
    if (accessToken) {
      fetchMeta = {
        headers : {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    try {
      const response = await axios.get(`${BACKENDURL}/jobs/jobList/${userID}`, fetchMeta)
      if (!(response.status === 200 || response.status === 201)) {
        throw new Error("Something went wrong when sending requests/ the userID is not valid.")
      }
      return {state : true, response : response}
    } catch (err) {
      return {state : false, response : undefined}
    }
  }


  const coreFetchData = async () => {
    setIsLoadingData(true)
    const functionResponse = await jwtAuthentication((accessToken) => fetchData(props.userId, accessToken), props.token, props.isSignedIn, props.refreshToken)
    const {state, response} = functionResponse
    if (!state) return
    const array = response.data.message
    setData(array)
    setIsLoadingData(false)
  }

  React.useEffect(() => {
    // if (!props.isSignedIn) {
    //   history.push("/")
    // } else {
      coreFetchData()
    // }
    const interval = setInterval(() => {
      coreFetchData()
    }, 300000);
    return () => clearInterval(interval)
  }, []);

  const onTaskDelete = () => {
    coreFetchData()
  };
    return (
      <div>
        <HeaderWhite />
        <Grid container>
          <Grid sm={2} xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="task-center-container">
              <div
                className="prediction-title-container"
                style={{
                  borderBottom: "1px lightGrey solid",
                }}
              >
                <h1>Task Center</h1>
              </div>
              {data.length !== 0 ? (
                data.map((a,i) => (
                  <TaskCard
                    data={a}
                    onTaskDelete={onTaskDelete}
                    key={i}
                  />
                ))
              ) : isLoadingData ? (
                <CircularProgress
                  size={24}
                  className="spinner"
                  color="#c4c4c4"
                  thickness={5}
                />
              ) : (
                <NoTask />
              )}
              {/* {data.map((a) => (
                <TaskCard data={a} />
              ))} */}
            </div>
          </Grid>
          <Grid sm={2} xs></Grid>
        </Grid>
      </div>
    );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    token: state.auth.token,
    userId: state.auth.Id,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, {})(TaskCenter);
