import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import image from "../../img/tipars-drawer-image.svg"

export default function TiparsDrawer() {
    const refType  =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
        useEffect(()=>{
            let drawerCell = document.getElementsByClassName("drawer-cell")
            for(let i=0; i<drawerCell.length; i++){
                drawerCell[i].style.backgroundColor="#370075"
            }
            // console.log(refType)
            let selectedCell = document.getElementById(refType)
            selectedCell?selectedCell.style.backgroundColor="#620097":null
        },[refType])
  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          // width: "16.7%",
          boxSizing: "border-box",
          position: "relative",
          backgroundColor:"#370075"
        },
      }}
      variant="permanent"
      anchor="left"
      id="tipars-drawer"
    >
      {/* <Divider /> */}
      <List id="drawer-tipars-cell">
        <p style={{
        margin:"10px 0 10px 8px",
        color:"white",
        fontSize:"16px"
      }} className="scroll-tip">Scroll to select reference trees 👇</p>
        <Link to={`/tipars`}>
          <ListItem key="TIPars" 
          disablePadding>
            {/* <div id="tipars"> */}
            <ListItemButton id="tipars" className="drawer-cell">
              <ListItemText 
              primary="TIPars" 
              primaryTypographyProps={{
                fontSize: 20,
                fontFamily: "PTRootUIWebBold",
                letterSpacing: 0,
                color:"white"
              }}/>
            </ListItemButton>
            {/* </div> */}
          </ListItem>
        </Link>
      <div className="divider" style={{
        borderBottom:"solid #620097 1px"
      }}/>
      <p style={{
        margin:"24px 0 10px 8px",
        color:"#C4C4C4",
        fontSize:"16px"
      }}>Reference Trees</p>
        {[{label:"Influenza-A-H5 HA Tree",url:"Influenza-A-H5_HA_Tree"},{label:"SARS-Cov-2 Full Genome Tree", url:"SARS-Cov-2_Full_Genome_Tree"}].map((text, index) => (
          <Link to={`/reference/` + text.url} >
            <ListItem key={text.label} disablePadding 
              style={{
              color:"white"}}>
              <ListItemButton id={text.url} className="drawer-cell">
                <ListItemText 
                primary={text.label}  
                primaryTypographyProps={{
                fontSize: 20,
                fontFamily: "PTRootUIWebBold",
                letterSpacing: 0,
                color:"white"}}/>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <div style={{
      width:"100%",
      position:"absolute",
      bottom:"0"}}>  
        <img src={image} style={{
            width:"60%",
            margin:"0 auto 60px auto",
        }}/>
      </div>
    </Drawer>
  );
}
