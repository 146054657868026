import React from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../../logo-light-theme.svg";
import TemporaryDrawerGreen from "../DrawerGreen";
import { Link, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "../../App.css";
import ProfileIcon from "../../img/profileIcon.svg";
import HeaderRightLoginedBlue from "./HeaderRightLoginedBlue";

const HeaderWhite = ({ isSignedIn }) => {
  return (
    <div
      className="header-container"
      style={{
        width: "100%",
        height: "96px",
        // boxShadow: "0 2px 12px rgba(0, 0, 0, 0.06)",
        borderBottom: "#370075 solid 1px",
      }}
    >
      <Grid container direction="column">
        <Grid item container>
          <Grid item sm={2} xs={2}>
            <div
              className="header-container-left"
              style={{
                height: "96px",
                display: "flex",
                marginRight: "0",
                float: "right",
              }}
            >
              <Link
                to={`/`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "40px",
                  float: "right",
                }}
              >
                <div className="logo">
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
              </Link>
              <TemporaryDrawerGreen />
            </div>
          </Grid>
          <Grid item sm xs>
            <div> </div>
          </Grid>
          <Grid item sm={2} xs={2}>
            <div
              className="header-container-right"
              style={{
                height: "96px",
              }}
            >
              <Switch>
                {isSignedIn ? (
                  <HeaderRightLoginedBlue />
                ) : (
                  <div
                    className="HeaderRight"
                    style={{
                      height: "96px",
                      display: "flex",
                      marginLeft: "0",
                      float: "left",
                    }}
                  >
                    <div
                      className="login"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <Link to={`/login`}>
                        <span
                          style={{
                            fontSize: "1.2rem",
                            color: "#370075",
                          }}
                        >
                          LOGIN
                        </span>
                      </Link>
                    </div>
                  </div>
                )}
              </Switch>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};
export default connect(mapStateToProps)(HeaderWhite);
