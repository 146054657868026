import { React, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Background from "../img/background-signup.jpg";
import { useParams } from "react-router-dom";
import { Link, Redirect } from "react-router-dom";
import logo from "../logo.svg";
import * as DisplayedMessages from "./DisplayedMessages";
import { BACKENDURL } from "../contants/backendSettings";
import useTabTitle from "../utils/useTabTitle";

const SuccessfulActivation = () => {
  useTabTitle("TIPars | Successful Activation");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [second, setSecond] = useState(5);
  const [isActivated, setIsActivated] = useState(false);

  const { uid, token } = useParams();

  useEffect(() => {
    const sendActivationRequest = async () => {
      try {
        const response = await fetch(
          `${BACKENDURL}/users/activate/${uid}/${token}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((res) => {
            if ("message" in res) {
              setIsActivated(true);
            }
            if ("error" in res) {
              // history.push("/unclearError");
              throw new Error(DisplayedMessages.UNCLEAR_ERROR);
            }
          });
      } catch (e) {
        // console.log(e.message);
      }
    };
    sendActivationRequest();
  });

  const fiveSecondRedirect = isActivated
    ? setInterval(() => {
        if (second <= 0) {
          setShouldRedirect(true);
          clearInterval(fiveSecondRedirect);
        } else {
          setSecond(second - 1);
        }
      }, 1000)
    : null;

  if (shouldRedirect) return <Redirect to="/login" />;
  if (isActivated) {
    return (
      <div
        className="container"
        style={{
          textAlign: "left",
        }}
      >
        <Grid
          container
          style={{
            height: "100vh",
          }}
        >
          <Grid item sm={4} xs="auto">
            <div
              className="content-left"
              style={{
                backgroundImage: `url(${Background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100%",
                margin: "0",
              }}
            >
              <Link to={`/`}>
                <div
                  className="HeaderLeft"
                  style={{
                    height: "96px",
                    display: "flex",
                    marginLeft: "8%",
                  }}
                >
                  <div
                    className="logo"
                    style={{
                      width: "48px",
                      height: "48px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginRight: "20px",
                      float: "right",
                    }}
                  >
                    <img
                      alt="logo"
                      src={logo}
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "1.8rem",
                      fontFamily: "PTRootUIBoldWoff2",
                      color: "white",
                      letterSpacing: "",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    E
                    <span
                      style={{
                        color: "#F87700",
                      }}
                    >
                      i
                    </span>
                    GEN
                  </p>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item sm={8} xs={12}>
            <div
              className="content-right"
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "80%",
                paddingTop: "100px",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontSize: "2rem",
                  letterSpacing: "1px",
                  paddingBottom: "20px",
                }}
              >
                Congratulations!
              </h1>
              <p>
                You have completed the steps of registration, you are welcome!
              </p>
              <Link
                to={`/`}
                style={{
                  display: "block",
                  color: "#370075",
                  paddingTop: "40px",
                  paddingBottom: "6px",
                }}
                href="#"
              >
                &lt; Go to Login
              </Link>
              <span
                style={{
                  color: "grey",
                }}
              >
                The page will jump after {second} second.
              </span>
              <div
                className="divider"
                style={{
                  width: "100%",
                  borderBottom: "lightgrey solid 1px",
                  paddingTop: "30px",
                }}
              ></div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <span>{DisplayedMessages.UNCLEAR_ERROR}</span>
      </div>
    );
  }
};

export default SuccessfulActivation;
