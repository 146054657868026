import * as DisplayedMessages from "../components/DisplayedMessages";
import {BACKENDURL} from "../contants/backendSettings"
import axios from 'axios'

const generateAccessToken = async (isSignedIn, refreshKey) => {
  // console.log(isSignedIn, refreshKey)
    if (!isSignedIn) return
    try {
        const formData = new FormData();
        formData.append("refresh", refreshKey)
        const response = await axios.post(`${BACKENDURL}/users/api/token/refresh`, formData)
        console.log("response: ", response)
        if (response.status === 200) {
            return response.data.access
        }
    } catch (err) {
        console.log("enter into error block")
    }
};

export default generateAccessToken;
