import React, { Component } from "react";
import "../../App.css";
import homeGraphic from "../../img/homeGraphic.png";
import homeGraphicMobile from "../../img/homeGraphicMobile.png";

export default function LandingContent() {
    // console.log(window.innerWidth)
    return (
      <div className="LandingContent">
        <img src={homeGraphic} alt='' className="homeGraphic"/>
        <img src={homeGraphicMobile} alt='' className="homeGraphicMobile"/>
      </div>
    )
}

