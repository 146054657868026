import { React } from "react";
import Grid from "@material-ui/core/Grid";
import "./FormSignup.css";
import FormSignup from "./FormSignup";
import { Link } from "react-router-dom";
import logo from "../../logo.svg";
import useTabTitle from "../../utils/useTabTitle";

const Signup = () => {
  useTabTitle("TIPars | Register");
  return (
    <div className="form-container1">
      <Link to={`/`}>
        <span className="close-btn1"> × </span>
      </Link>
      <Grid
        container
        style={{
          height: "100vh",
        }}
      >
        <Grid item sm={4} xs="auto">
          <div className="form-content-left1">
            <Link to={`/`}>
              <div
                className="HeaderLeft"
                style={{
                  height: "96px",
                  display: "flex",
                  marginLeft: "8%",
                }}
              >
                <div
                  className="logo"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "20px",
                    float: "right",
                  }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: "1.8rem",
                    fontFamily: "PTRootUIWebBold",
                    color: "white",
                    letterSpacing: "",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  TIPars
                </p>
              </div>
            </Link>
          </div>
        </Grid>
        <Grid item sm={8} xs={12}>
          <FormSignup />
          {/* {!isSubmitted ? (
                        <FormSignup submitForm={submitForm} />
                      ) : (
                        <Activation />
                      )} */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Signup;
