import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import Grid from "@material-ui/core/Grid";
import "./signin/FormSignin.css";
import { connect } from "react-redux";
import { signIn, signOut } from "../actions";
import history from "./history";
import { useForm } from "react-hook-form";
import * as DisplayedMessages from "./DisplayedMessages";
import generateAccessToken from "../utils/GenerateAccessToken";
import { BACKENDURL } from "../contants/backendSettings";
import useTabTitle from "../utils/useTabTitle";

//dangerous!!!
const ChangePassword2 = (props) => {
  useTabTitle("TIPars | Change Password");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onChange",
  });

  // const [userId, setUserId] = useState(-1)

  // useEffect(() => {
  //   const getFetchedUser = async () => {
  //     const userEmail = props.userEmail ? props.userEmail : null;
  //     const response = await mapEmailToDetails(userEmail)
  //     if (response !== undefined && response !== null) {
  //       setUserId(response.userId)
  //     }
  //   }
  //   getFetchedUser();
  // }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.target.reset();
    // console.log(data);
    const fetchChangedPassword = async (accessToken) => {
      let accessAcquiredTime = 0;
      try {
        await fetch(`${BACKENDURL}/users/${props.userId}/changePassword`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: JSON.stringify({
            new_password: data.password,
            confirm_new_password: data.password2,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (response === 1) {
              // console.log(DisplayedMessages.SUCCESS_UPDATED);
              // alert(DisplayedMessages.SUCCESS_UPDATED)
              history.push("./changePassword3");
            } else if (
              response.detail === "Given token not valid for any token type" ||
              response.detail ===
                "Authentication credentials were not provided."
            ) {
              // console.log("run into refresh block in changePassword2");
              generateAccessToken(
                props.isSignedIn,
                props.refreshToken,
                (res) => {
                  fetchChangedPassword(res);
                }
              );
              accessAcquiredTime += 1;
              if (accessAcquiredTime >= 3) {
                throw new Error(
                  DisplayedMessages.EXCEED_MAX_ACCESS_TOKEN_LIMIT
                );
              }
            } else {
              throw new Error(DisplayedMessages.PASSWORD_MATCHES);
            }
          });
      } catch (err) {
        if (err.message === "Failed to fetch") {
          // console.log(DisplayedMessages.CONNECTION_ERROR);
        } else {
          // console.log(DisplayedMessages.PASSWORD_MATCHES);
        }
      }
    };

    const { password, password2 } = data;
    if (password !== password2) {
      setError("password", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
      setError("password2", {
        type: "matches",
        message: DisplayedMessages.PASSWORD_MATCHES,
      });
    } else {
      fetchChangedPassword(props.userToken);
    }
  };

  if (props.isSignedIn && props.location.state === props.userToken) {
    return (
      <div className="change-password-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm={2} xs="auto"></Grid>
          <Grid item sm={8} xs={12}>
            <div className="fgpw-form-container">
              <h1
                style={{
                  fontSize: "2rem",
                  color: "black",
                  letterSpacing: "1px",
                }}
              >
                Set New Password
              </h1>
              <div className="form-inputs">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <label className="form-label"> Password </label>
                  <input
                    className="form-input"
                    type="password"
                    placeholder="Enter your password"
                    {...register("password", {
                      required: true,
                      maxLength: 20,
                      minLength: 8,
                      validate: (value) => {
                        if (value.toLowerCase() === value) {
                          return false;
                        }
                        if (!/(?=.*[!@#$%^&*])/.test(value)) {
                          return false;
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.password?.type === "required" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.REQUIRED}
                    </span>
                  )}
                  {errors.password?.type === "maxLength" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_ATMOST_20}
                    </span>
                  )}
                  {errors.password?.type === "minLength" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_ATLEAST_8}
                    </span>
                  )}
                  {errors.password?.type === "validate" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_COMBIN}
                    </span>
                  )}
                  {errors.password && (
                    <span style={{ color: "#F87700" }}>
                      {errors.password.message}
                    </span>
                  )}
                  <div
                    style={{
                      height: "20px",
                    }}
                  ></div>
                  <label className="form-label"> Confirm Password </label>
                  <input
                    className="form-input"
                    type="password"
                    placeholder="Confirm your password"
                    {...register("password2", {
                      required: true,
                      maxLength: 20,
                      minLength: 8,
                      validate: (value) => {
                        if (value.toLowerCase() === value) {
                          return false;
                        }
                        if (!/(?=.*[#?!@$%^&*-.])/.test(value)) {
                          // if (!/(?=.*[!@#$%^&*])/.test(value)) {
                          return false;
                        }
                        return true;
                      },
                    })}
                  />
                  {errors.password2?.type === "required" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.REQUIRED}
                    </span>
                  )}
                  {errors.password2?.type === "maxLength" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_ATMOST_20}
                    </span>
                  )}
                  {errors.password2?.type === "minLength" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_ATLEAST_8}
                    </span>
                  )}
                  {errors.password2?.type === "validate" && (
                    <span style={{ color: "#F87700" }}>
                      {DisplayedMessages.PASSWORD_COMBIN}
                    </span>
                  )}
                  {errors.password2 && (
                    <span style={{ color: "#F87700" }}>
                      {errors.password2.message}
                    </span>
                  )}
                  <br />
                  <button className="form-input-btn" type="submit">
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item sm={2} xs="auto"></Grid>
        </Grid>
      </div>
    );
  } else {
    history.push("/");
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    userId: state.auth.Id,
    userToken: state.auth.token,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, { signIn, signOut })(ChangePassword2);
