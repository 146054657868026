export const REQUIRED = "This field is required."
export const EMAIL_FORMAT = "Wrong email format."
export const EMAIL_MATCHES = "Emails don't match."
export const PASSWORD_MATCHES = "Passwords don't match."
export const EMAIL_AND_PASSWORD_MATCHES = "Emails don't match and passwords don't match."
export const EMAIL_OR_PASSWORD_MATCHES = "Emails don't match or passwords don't match."
export const LENGTH_64 = "This field should be at most 64 characters."
export const LENGTH_100 = "This field should be at most 100 characters."
export const PASSWORD_ATMOST_20 = "Password must be at most 20 characters."
export const PASSWORD_ATLEAST_8 = "Password must be at least 8 characters."
export const PASSWORD_COMBIN = "Password must contain at least 1 special characters and 1 uppercase. (special characters: #?!@$%^&*-.)"
export const LOCATION_UNSELECTED = "Location is not selected."
export const CONNECTION_ERROR = "Connection error, please check your connection."
export const UNCLEAR_ERROR_PLEASE_TRY_AGAIN = "Unclear error, please try again later."
export const REFRESH_REQUIRED = "Refresh field is required."
export const EXCEED_MAX_CHAR_LIMIT = "Too much text on this field." // TODO: change the error message, used in contact us
export const EXCEED_MAX_ACCESS_TOKEN_LIMIT = "Too many requests for access token."

//////////////////////////////////////////////////////////////////////////////////////////////////////////
////backend related
export const EMAIL_BEING_USED = "This email is already being used."
export const ACCOUNT_NOT_EXIST = "This account does not exist."
export const OLD_PASSWORD_INCORRECT = "The inputed password is incorrect."
export const UNCLEAR_ERROR = "Somethings went wrong."
export const EMAIL_UNSEND = "Something went wrong when sending email. Please try it later."
export const LOCATION_FETCHING_ERROR = "Something went wrong when fetching locations."
export const USER_NOT_EXISTED = 'User does not exist.'
export const USER_NOT_EXISTED_OR_PASSWORD_WRONG = "User does not exist or password is wrong."
export const TOKEN_UNDEFINED = "Token is undefined."
export const TOKEN_EXPIRED = "Token is expired, please Login and try again."
export const REFRESH_TOKEN_EXPIRED = "Refresh token is expired, please Login and try again."
export const REFRESH_TOKEN_EXPIRED_FORGET_PASSWORD = "Refresh token is expired, please request again."

//////////////////////////////////////////////////////////////////////////////////////////////////////////
////success related
export const SUCCESS_UPDATED = "Sucessfully update!"
export const LOCATION_SUCCESS_UPDATED = "Successfully updated location."

