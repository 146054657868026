import "../../App.css";
import React, { Component } from "react";

class LandingCite extends Component {
  render() {
    return (
      <div className="cite-container">
        <div className="cite-content">
          <h3>Please cite us!</h3>
          <div style={{
            marginTop: "10px",
            paddingBottom: "80px",
            marginBlockEnd: "0",
            textAlign:"left"
          }}>
          <p
            style={{
              color: "white",
              textAlign:"left"
            }}
            
          >
            Ye Y, Shum M, Tsui J, Yu G, Smith D, Zhu H, Wu J, Guan Y, Lam TT.
            Robust expansion of phylogeny for fast-growing genome sequence data.
            (2022) bioRxiv 2021.12.30.474610 <a href="https://www.biorxiv.org/content/10.1101/2021.12.30.474610v1.full"
            target="blank">🔗</a>
          </p>
          </div>
        </div>
      </div>
    );
  }
}
export default LandingCite;
