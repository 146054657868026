import { React, useState } from "react";
import Grid from "@material-ui/core/Grid";
import "./FormSignin.css";
import FormSignin from "./FormSignin";
import { Link } from "react-router-dom";
import Home from "../home/Home";
import logo from "../../logo.svg";
import useTabTitle from "../../utils/useTabTitle";

const Signin = () => {
  useTabTitle("TIPars | Sign In");
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <div className="form-container">
      <Link to={`/`}>
        <span className="close-btn"> × </span>
      </Link>
      <Grid container>
        <Grid item md={8} sm={4} xs>
          <div className="form-content-left">
            <Link to={`/`}>
              <div
                className="HeaderLeft"
                style={{
                  height: "96px",
                  display: "flex",
                  marginLeft: "8%",
                }}
              >
                <div
                  className="logo"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "20px",
                    float: "right",
                  }}
                >
                  <img
                    alt="logo"
                    src={logo}
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: "1.8rem",
                    fontFamily: "PTRootUIWebBold",
                    color: "white",
                    letterSpacing: "",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  TIPars
                </p>
              </div>
            </Link>
            {/* <FadingImg /> */}
          </div>
        </Grid>
        <Grid
          item
          md={4}
          sm={8}
          xs={12}
          style={{
            height: "100vh",
          }}
        >
          <div className="form-content-right">
            {!isSubmitted ? <FormSignin submitForm={submitForm} /> : <Home />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Signin;
