import React, { useState, useEffect } from "react";
import "./FormSignin.css";
import Divider from "./Divider";
import GoogleLogin from "./GoogleLogin";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { signIn, LocalStorageBoolean } from "../../actions";
import history from "../history";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm } from "react-hook-form";
import * as DisplayedMessages from "../DisplayedMessages";
import { saveLocalStorage } from "../localStorage";
import { BACKENDURL } from "../../contants/backendSettings";

const FormSignin = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [state, setState] = useState({
    checked: true,
  });
  const checkboxHandleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    props.LocalStorageBoolean(event.target.checked);
    saveLocalStorage(event.target.checked);
  };

  useEffect(() => {
    if (state.checked) {
      props.LocalStorageBoolean(true);
    } else {
      props.LocalStorageBoolean(false);
    }
    saveLocalStorage(state.checked);
  }, []);

  const onSubmit = (data, e) => {
    e.target.reset();
    e.preventDefault();
    // console.log(data);
    const fetchUserInfo = async () => {
      try {
        await fetch(`${BACKENDURL}/users/api/token`, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ email: data.email, password: data.password }),
        })
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            // console.log(res);
            if ("detail" in res) {
              if (
                res.detail ===
                "No active account found with the given credentials"
              ) {
                setError("email", {
                  type: "matches",
                  message: DisplayedMessages.USER_NOT_EXISTED_OR_PASSWORD_WRONG,
                });
                setError("password", {
                  type: "matches",
                  message: DisplayedMessages.USER_NOT_EXISTED_OR_PASSWORD_WRONG,
                });
                throw new Error(
                  DisplayedMessages.USER_NOT_EXISTED_OR_PASSWORD_WRONG
                );
              } else {
                history.push("/unclearError");
                throw new Error(DisplayedMessages.UNCLEAR_ERROR);
              }
            } else if ("refresh" in res && "access" in res) {
              props.signIn(
                "d24h",
                null,
                res.user_id,
                res.name,
                res.access,
                res.email,
                res.refresh,
                res.location
              );
              props.LocalStorageBoolean(state.checkedA);
              saveLocalStorage(state.checkedA);
              history.push("/taskCenter");
            } else {
              history.push("/unclearError");
              throw new Error(DisplayedMessages.UNCLEAR_ERROR);
            }
          });
      } catch (err) {
        if (
          err.message === DisplayedMessages.USER_NOT_EXISTED_OR_PASSWORD_WRONG
        ) {
          // console.log(DisplayedMessages.USER_NOT_EXISTED_OR_PASSWORD_WRONG);
        } else if (err.message === "Failed to fetch") {
          history.push("/serverError");
          // console.log(DisplayedMessages.CONNECTION_ERROR);
        } else {
          // console.log(err.message);
        }
      }
    };
    fetchUserInfo();
  };

  return (
    <div className="right-container">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form-signin"
        id="sign-in-form"
        noValidate
      >
        <h1>LOGIN</h1>
        <div className="form-inputs">
          <label className="form-label"> Email </label>
          <input
            className="form-input"
            type="email"
            {...register("email", { required: true })}
            placeholder="Enter your email"
          />
          {errors.email?.type === "required" && (
            <span>{DisplayedMessages.REQUIRED}</span>
          )}
          {errors.email && <span>{errors.email.message}</span>}
        </div>
        <div className="form-inputs">
          <label className="form-label"> Password </label>
          <input
            className="form-input"
            type="password"
            placeholder="Enter your password"
            {...register("password", { required: true })}
          />
          {errors.password?.type === "required" && (
            <span>{DisplayedMessages.REQUIRED}</span>
          )}
          {errors.password && <span>{errors.password.message}</span>}
        </div>
        <div
          className="checkBox"
          style={{
            width: "80%",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checked}
                onChange={checkboxHandleChange}
                name="checked"
                color="primary"
                disabled={false}
                disableRipple={true}
              />
            }
          />
          <span
            style={{
              fontSize: "1rem",
              color: "black",
              marginTop: "12px",
            }}
          >
            Remember me
          </span>
        </div>
        <button className="form-input-btn2" type="submit" form="sign-in-form">
          Login
        </button>
        <div className="turn-to-login">
          <span className="form-input-login">
            Do not have an account ? <Link to={`/register`}> Register </Link>
          </span>
        </div>
        <div className="turn-to-forget-password">
          <span className="form-input-login">
            <Link to={`/forgetPassword1`}> Forget Password?</Link>
          </span>
        </div>
        <div className="divider-container">
          <Divider />
        </div>
        <GoogleLogin />
      </form>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};

export default connect(mapStateToProps, { signIn, LocalStorageBoolean })(
  FormSignin
);
