import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderRight extends Component {
  render() {
    return (
      <div
        className="HeaderRight"
        style={{
          height: "96px",
          display: "flex",
          marginLeft: "0",
          float: "left",
        }}
      >
        <div
          className="login"
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            color: "white",
          }}
        >
          <Link to={`/login`}>
            <span
              id="login"
              style={{
                fontSize: "1.2rem",
              }}
            >
              LOGIN
            </span>
          </Link>
        </div>
      </div>
    );
  }
}
export default HeaderRight;
