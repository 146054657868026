import React, { useEffect, useState, useNavigate } from "react";
import "../../styles/core.css";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { StyledEngineProvider } from "@mui/styled-engine";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import { validation } from "../../utils/validation";
// import { preprocessing } from "../../utils/preprocessing";
import { connect } from "react-redux";
import { BACKENDURL } from "../../contants/backendSettings";
import { FRONTENDURL } from "../../contants/frontendSettings";
import { useForm } from "react-hook-form";
import * as DisplayedMessages from "../DisplayedMessages";
import { CircularProgress } from "@material-ui/core";
import jwtAuthentication from "../../utils/jwtAuthentication";
// import {useNavigate} from 'react-router-dom';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Preview = ({
  handleClose,
  open,
  rows,
  dropdowns,
  ...props
}) => {


  const { register, handleSubmit, watch, formState: { errors }, setError } = useForm();

  let refType = props.refType

  const [requestSent, setRequestSent] = useState({
    state: false,
    message: null,
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filesError, setFilesError] = useState(false)
  const [respnoseError, setResponseError] = useState({
    state: false,
    message: ""
  })

  const fetchData = async (formData, accessToken = null) => {
    try {
      let fetchMeta = {}
      if (accessToken) {
        fetchMeta = {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      }
      const response = await axios.post(`${BACKENDURL}/jobs/`, formData, fetchMeta)
      if (!(response.status === 200 || response.status === 201)) {
        throw new Error("something went wrong when fetching to the backend")
      }
      return { state: true, response: response }
    } catch (err) {
      console.err(err)
      return { state: false, response: undefined }
    }
  }

  const onSubmit = async (data) => {

    setIsSubmitting(true)
    // handleClose(false)
    // later need to add projectName and email on the backend 
    // also need to add user system to make the 'user' foreign key point to a particular user
    let { email, projectName } = data
    let [query_seq_file, tree_file, taxa_seq_file, anc_seq_file] = rows
    let formData = new FormData()
    let uploaded = true

    // const dropdowns = [
    //   {
    //     selectedOption: selectedOption0,
    //     label: "with_past_ml: ",
    //     setSelectedOption: setSelectedOption0,
    //     options: [
    //       {
    //         value: "true",
    //         label: "Yes",
    //       },
    //       {
    //         value: "false",
    //         label: "No",
    //       },
    //     ],
    //   },
    //   {
    //     selectedOption: selectedOption1,
    //     label: "algo_type: ",
    //     setSelectedOption: setSelectedOption1,
    //     options: [
    //       {
    //         value: "placement",
    //         label: "placement",
    //       },
    //       {
    //         value: "insertion",
    //         label: "insertion",
    //       },
    //     ],
    //   },
    //   {
    //     selectedOption: selectedOption2,
    //     label: "placement_mode: ",
    //     setSelectedOption: setSelectedOption2,
    //     options: [
    //       {
    //         value: "true",
    //         label: "Yes",
    //       },
    //       {
    //         value: "false",
    //         label: "No",
    //       },
    //     ],
    //   },
    // ];


    // console.log("projectName: ", projectName)
    // console.log("email: ", email)
    // console.log("query_seq_file", query_seq_file)
    // console.log("tree_file", tree_file)
    // console.log("taxa_seq_file", taxa_seq_file)
    // console.log("anc_seq_file", anc_seq_file)

    try {
      formData.append("project_name", projectName)
      formData.append("email", email)
      formData.append("query_seq_file", query_seq_file.selectedFile, query_seq_file.selectedFile.name)
      if (props.refType == "tipars") {
        formData.append("tree_file", tree_file.selectedFile, tree_file.selectedFile.name)
        formData.append("taxa_seq_file", taxa_seq_file.selectedFile, taxa_seq_file.selectedFile.name)
        if (dropdowns[1].selectedOption === true) formData.append("query_aligned", 'false')
        if (dropdowns[1].selectedOption === false) formData.append("query_aligned", 'true')
        if (dropdowns[0].selectedOption === true) {
          formData.append("with_past_ml", 'true')
        } else {
          formData.append("anc_seq_file", anc_seq_file.selectedFile, anc_seq_file.selectedFile.name)
          formData.append("with_past_ml", 'false')
        }
      } else {
        formData.append("query_aligned", 'false')
        formData.append("with_past_ml", 'false')
      }
      formData.append('algo_type', 'insertion')
      formData.append("placement_mode", 'true')
      if (props.isSignedIn) formData.append("user", props.userId)
      if (refType && refType !== "tipars") formData.append("input_set", refType) 
      console.log(formData)
    } catch (e) {
      console.log("You enter into the error block")
      setFilesError(true)
      uploaded = false
    }
    if (!uploaded) {
      setIsSubmitting(false)
      return
    }
    const functionResponse = await jwtAuthentication((accessToken) => fetchData(formData, accessToken), props.token, props.isSignedIn, props.refreshToken)
    const { state, response } = functionResponse
    console.log(functionResponse)
    if (!state) {
      setResponseError({
        state: true,
        message: "Something went wrong when fetching to backend."
      })
      setIsSubmitting(false)
    } else {
      const { algo_type, created, duration, expiry_date, finished, id, placement_mode, started, status, user, with_past_ml } = response.data
      setIsSubmitting(false)
      setRequestSent({
        state: true,
        message: `${FRONTENDURL}/processTask/${id}`
      })
    }
  }

  useEffect(() => {
    setRequestSent({
      state: false,
      message: null,
    });
    setIsSubmitting(false);
    setFilesError(false);
    setResponseError({
      state: false,
      message: null,
    })
  }, [open]);

  useEffect(() => {
    console.log(rows)
    if (requestSent.state) {
      window.open(requestSent.message, "_blank")
    }
  }, [requestSent])

  if (!requestSent.state) {
    return (
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={() => handleClose(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => handleClose(false)}
          ></BootstrapDialogTitle>
          <DialogContent
            style={{
              marginTop: "10px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} id="previewForm">
              <div className="preview-cell">
                <label>Project Name: </label>
                <input
                  className="preview-input"
                  type="text"
                  placeholder="Untitled Project"
                  name="projectName"
                  {...register("projectName", {
                    required: true,
                    maxLength: 100,
                  })}
                ></input>
                {errors.projectName?.type === "required" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.projectName?.type === "maxLength" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.LENGTH_100}
                  </span>
                )}
              </div>
              <div className="preview-cell">
                <label>Email: </label>
                <input
                  className="preview-input"
                  type="email"
                  placeholder={props.isSignedIn ? props.userEmail : "Required"}
                  defaultValue={props.isSignedIn ? props.userEmail : ""}
                  name="email"
                  {...register("email", {
                    required: true,
                    maxLength: 255,
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                ></input>
                {errors.email?.type === "required" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.REQUIRED}
                  </span>
                )}
                {errors.email?.type === "pattern" && (
                  <span style={{ color: "red" }}>
                    {DisplayedMessages.EMAIL_FORMAT}
                  </span>
                )}
              </div>

              <div className="preview-cell">
                <label>Tipars Command: </label>
                <div className="command-area">
                  <p>
                    {/* {inputSequence ? inputSequence :  */}"Not applicable"
                  </p>
                </div>
                {/* {sequenceError.state && (
                  <p style={{ color: "red" }}>{sequenceError.message}</p>
                )}
                </div>*/}
              </div>
              <div className="preview-buttons">
                {/* <button
                  className="secondary-button"
                  style={{
                    minWidth: "120px",
                  }}
                >
                  Back
                </button> */}
                <button
                  type="submit"
                  className={
                    filesError || isSubmitting
                      ? "disabled-button"
                      : "primary-button"
                  }
                  style={{
                    minWidth: "120px",
                    marginLeft: "30px",
                  }}
                  form="previewForm"
                  disabled={
                    filesError || isSubmitting
                  }
                >
                  {isSubmitting ? (
                    <CircularProgress
                      size={24}
                      className="spinner"
                      thickness={5}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
            {respnoseError.state ? <p style={{ color: 'red' }}>{respnoseError.message}</p> : null}
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <BootstrapDialog
          onClose={() => handleClose(false)}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => handleClose(false)}
          ></BootstrapDialogTitle>
          <DialogContent
            style={{
              marginTop: "10px",
            }}
          >
            <div className="preview-cell">
              <label>Congratulations!</label>
              <p style={{
                marginTop: "20px"
              }}>Your task submitted successfully.</p>
              {/* <a href={requestSent.message}>Please check the status here.</a> */}
            </div>
          </DialogContent>
        </BootstrapDialog>
      </StyledEngineProvider>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    refreshToken: state.auth.refresh,
  };
};

export default connect(mapStateToProps, {})(Preview);
