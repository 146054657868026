import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import menuIcon from "../icons/menuIcon.svg";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import PTRootUIMediumWoff2 from "../font/PT/PT-Root-UI_Medium.woff2";

const PTRootUIMedium = {
  fontFamily: "PTRootUIMedium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('PTRootUIMedium'),
    local('PT-Root-UI_Medium'),
    url(${PTRootUIMediumWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createTheme({
  typography: {
    fontFamily: "PTRootUIMedium",
  },
  palette: {
    primary: {
      main: "#370075",
      dark: "#233132",
      light: "#98C1D9",
    },
    secondary: {
      main: "#F87700",
      dark: "#A53327",
      light: "#F1AE8B",
    },
    texyPrimary: {
      main: "#000000",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [PTRootUIMedium],
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiButtonBase: {
      root: {
        color: "white",
        fontFamily: "PTRootUIMedium",
      },
    },
    Divider: {
      width: "90%",
      margin: "auto",
    },
  },
});

const useStyles = makeStyles({
  list: {
    width: "260px",
    height: "100%",
    fontSize: "1.1rem",
    lineHeight: "40px",
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <ThemeProvider theme={theme}>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{
          backgroundColor: "#370075",
          color: "#fff",
        }}
      >
        <List
          style={{
            marginTop: "22px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div
            className="drawer-logo-container"
            style={{
              display: "flex",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                height: "36px",
                marginLeft: "16px",
                marginBottom: "80px",
              }}
            />
            <p
              style={{
                fontSize: "1.4rem",
                fontFamily: "PTRootUIWebBold",
                color: "white",
                marginTop: "0.7rem",
                marginLeft: "12px",
                letterSpacing: "",
              }}
            >
              TIPars
            </p>
          </div>

          <Link to={`/`}>
            <ListItem button key="introduction">
              HOME
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/about`}>
            <ListItem button key="about">
              ABOUT
            </ListItem>
          </Link>
          <Divider />
          {/* <Link to={`/recentUpdate`}>
            <ListItem button key="rencentUpdate">
              RECENT UPDATE
            </ListItem>
          </Link> */}
          {/* <Divider />
          <Link to={`/tutorial`}>
            <ListItem button key="tutorial">
              TUTORIAL
            </ListItem>
          </Link>
          <Divider /> */}
          <Link to={`/ourTeam`}>
            <ListItem button key="ourTeam">
              OUR TEAM
            </ListItem>
          </Link>

          <Divider />
          <Link to={`/FAQ`}>
            <ListItem button key="faq">
              FAQ
            </ListItem>
          </Link>
          <Divider />
          <Link to={`/contactUs`}>
            <ListItem button key="feedback">
              FEEDBACK
            </ListItem>
          </Link>
          <Divider />
        </List>
      </div>
    </ThemeProvider>
  );

  return (
    <div
      style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      {["left"].map((anchor) => (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)} disableRipple>
              {/* {anchor} */}
              <img
                src={menuIcon}
                alt="menuIcon"
                style={{
                  width: "24px",
                  height: "24px",
                  float: "right",
                }}
              />
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              disableScrollLock={true}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        </ThemeProvider>
      ))}
    </div>
  );
}
