import * as DisplayedMessages from "../components/DisplayedMessages";
import {BACKENDURL} from "../contants/backendSettings"

const mapEmailToDetails = async (email) => {
  let response = null;
  try {
    response = await fetch(`${BACKENDURL}/users/getDetails`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((res) => {
        if ("error" in res) {
          throw Error(DisplayedMessages.USER_NOT_EXISTED);
        } else {
          return res;
        }
      });
  } catch (err) {
    if (err.message === "Failed to fetch") {
      alert(DisplayedMessages.CONNECTION_ERROR);
    } else {
      alert(err.message);
    }
  }
  return response;
};

export default mapEmailToDetails;
