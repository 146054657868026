import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PTRootUIMediumWoff2 from "../font/PT/PT-Root-UI_Medium.woff2";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import sampleData from "./samples2.json";

const PTRootUIMedium = {
  fontFamily: "PTRootUIMedium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
        local('PTRootUIMedium'),
        local('PT-Root-UI_Medium'),
        url(${PTRootUIMediumWoff2}) format('woff2')
      `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createTheme({
  typography: {
    fontFamily: "PTRootUIMedium",
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [PTRootUIMedium],
      },
    },
    MuiTableHead: {
      root: {
        // borderBottom: "#3D5A80 1px solid",
        backgroundColor: "#E5E5E5",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      rounded: {
        borderRadius: "2px",
      },
    },
    MuiTableCell: {
      root: {
        // maxWidth: "150px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "0.8rem",
      },
    },
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    margin: "auto",
    boxShadow: "none",
    padding: "none",
  },
}));

const objectProps = {
  breakpoint1: 1400,
  breakpoint2: 900,
  breakpoint3: 500,
};

const statusColor = (row) => {
  if (row.status == "Failure") {
    return "#F87700";
  } else if (row.status == "Success") {
    return "#98C1D9";
  } else if (row.status == "Processing") {
    return "#EBAB69";
  } else {
    return "grey";
  }
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
function createData(data) {
  return { ...data };
}
function dataFormatter(data) {
  let formattedData = data;
  formattedData.submission_time = new Date(
    data.submission_time * 1000
  ).customFormat("#YYYY#-#MM#-#DD# at #hh#:#mm#:#ss#");
  if (data.status != "3") {
    formattedData.end_time = new Date(data.end_time * 1000).customFormat(
      "#YYYY#-#MM#-#DD# at #hh#:#mm#:#ss#"
    );
  } else {
    formattedData.end_time = "-";
  }
  formattedData.status =
    data.status == 0
      ? "Success"
      : data.status == 1
      ? "Failure"
      : data.status == 2
      ? "In queue"
      : "Processing";
  return formattedData;
}

Date.prototype.customFormat = function (formatString) {
  var YYYY,
    YY,
    MMMM,
    MMM,
    MM,
    M,
    DDDD,
    DDD,
    DD,
    D,
    hhhh,
    hhh,
    hh,
    h,
    mm,
    m,
    ss,
    s,
    ampm,
    AMPM,
    dMod,
    th;
  YY = ((YYYY = this.getFullYear()) + "").slice(-2);
  MM = (M = this.getMonth() + 1) < 10 ? "0" + M : M;
  MMM = (MMMM = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][M - 1]).substring(0, 3);
  DD = (D = this.getDate()) < 10 ? "0" + D : D;
  DDD = (DDDD = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ][this.getDay()]).substring(0, 3);
  th =
    D >= 10 && D <= 20
      ? "th"
      : (dMod = D % 10) == 1
      ? "st"
      : dMod == 2
      ? "nd"
      : dMod == 3
      ? "rd"
      : "th";
  formatString = formatString
    .replace("#YYYY#", YYYY)
    .replace("#YY#", YY)
    .replace("#MMMM#", MMMM)
    .replace("#MMM#", MMM)
    .replace("#MM#", MM)
    .replace("#M#", M)
    .replace("#DDDD#", DDDD)
    .replace("#DDD#", DDD)
    .replace("#DD#", DD)
    .replace("#D#", D)
    .replace("#th#", th);
  h = hhh = this.getHours();
  if (h == 0) h = 24;
  if (h > 12) h -= 12;
  hh = h < 10 ? "0" + h : h;
  hhhh = hhh < 10 ? "0" + hhh : hhh;
  AMPM = (ampm = hhh < 12 ? "am" : "pm").toUpperCase();
  mm = (m = this.getMinutes()) < 10 ? "0" + m : m;
  ss = (s = this.getSeconds()) < 10 ? "0" + s : s;
  return formatString
    .replace("#hhhh#", hhhh)
    .replace("#hhh#", hhh)
    .replace("#hh#", hh)
    .replace("#h#", h)
    .replace("#mm#", mm)
    .replace("#m#", m)
    .replace("#ss#", ss)
    .replace("#s#", s)
    .replace("#ampm#", ampm)
    .replace("#AMPM#", AMPM);
};

const rows = sampleData.data.map((row) => createData(dataFormatter(row)));

function Row(props) {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  };
  const time = (i) => {
    if (size.x >= objectProps.breakpoint3) {
      return i;
    } else {
      return i.slice(0, i.length - 12);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const { row } = props;
  const [open, setOpen] = useState(false);
  const SideRightTableCell = withStyles({
    root: {
      width: size.x >= objectProps.breakpoint3 ? "8%" : "2%",
      padding: "0",
    },
  })(TableCell);
  const SideTableCell = withStyles({
    root: {
      width: "8%",
      padding: "0",
    },
  })(TableCell);
  const MidTableCell = withStyles({
    root: {
      display:
        (objectProps.breakpoint2 < size.x) & (size.x < objectProps.breakpoint1)
          ? "auto"
          : "none",
    },
  })(TableCell);
  const NarrowTableRow = withStyles({
    root: {
      display: size.x < objectProps.breakpoint2 ? "auto" : "none",
    },
  })(TableRow);

  return (
    <React.Fragment>
      <TableRow>
        <SideTableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </SideTableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{
            maxWidth: size.x >= objectProps.breakpoint3 ? "220px" : "100px",
          }}
        >
          {row.uid}
        </TableCell>
        <MidTableCell
          align="center"
          style={{
            maxWidth: "160px",
          }}
        >
          {row.query_filename}
        </MidTableCell>
        <TableCell
          align="right"
          style={{
            width: "120px",
            color: statusColor(row),
            fontWeight: "600",
            letterSpacing: "1px",
          }}
        >
          {row.status}
        </TableCell>
        <SideRightTableCell />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <NarrowTableRow key={row.query_filename}>
                    <TableCell component="th" scope="row">
                      Input&nbsp;filename
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        maxWidth:
                          size.x >= objectProps.breakpoint3 ? "180px" : "140px",
                      }}
                    >
                      {row.query_filename}
                    </TableCell>
                  </NarrowTableRow>
                  <TableRow>
                    <TableCell>Submitted&nbsp;on</TableCell>
                    <TableCell align="right">
                      {time(row.submission_time)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Finished&nbsp;on</TableCell>
                    <TableCell align="right">{time(row.end_time)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Query#</TableCell>
                    <TableCell align="right">{row.query_num}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ProfileTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });
  const updateSize = () => {
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const WideTableRow = withStyles({
    root: {
      display: size.x >= objectProps.breakpoint1 ? "auto" : "none",
    },
  })(TableRow);
  const MidTable = withStyles({
    root: {
      display:
        (objectProps.breakpoint2 < size.x) & (size.x < objectProps.breakpoint1)
          ? "auto"
          : "none",
    },
  })(Table);
  const NarrowTable = withStyles({
    root: {
      display: size.x > objectProps.breakpoint2 ? "none" : "auto",
    },
  })(Table);
  const SideTableCell = withStyles({
    root: {
      width: "8%",
      padding: "0",
    },
  })(TableCell);
  const SideRightTableCell = withStyles({
    root: {
      width: size.x >= objectProps.breakpoint3 ? "8%" : "2%",
      padding: "0",
    },
  })(TableCell);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <WideTableRow>
              <TableCell>JOB ID</TableCell>
              <TableCell align="center">Input&nbsp;filename</TableCell>
              <TableCell align="center">Submitted&nbsp;on</TableCell>
              <TableCell align="center">Finished&nbsp;on</TableCell>
              <TableCell align="center">Query#</TableCell>
              <TableCell align="right">Status</TableCell>
            </WideTableRow>
            <NarrowTable>
              <SideTableCell align="left" />
              <TableCell align="left">JOB ID</TableCell>
              <TableCell align="right">Status</TableCell>
              <SideRightTableCell align="right" />
            </NarrowTable>
            <MidTable>
              <SideTableCell align="left" />
              <TableCell
                align="left"
                style={{
                  width: "220px",
                }}
              >
                JOB ID
              </TableCell>
              <TableCell align="center">Input filename</TableCell>
              <TableCell
                align="right"
                style={{
                  width: "120px",
                }}
              >
                Status
              </TableCell>
              <SideTableCell align="right" />
            </MidTable>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <React.Fragment>
                <WideTableRow key={row.name}>
                  <TableCell
                    align="left"
                    style={{
                      maxWidth: "180px",
                    }}
                  >
                    {row.uid}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      maxWidth: "180px",
                    }}
                  >
                    {row.query_filename}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: "160px",
                    }}
                  >
                    {row.submission_time}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: "160px",
                    }}
                  >
                    {row.end_time}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: "80px",
                    }}
                  >
                    {row.query_num}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      minWidth: "80px",
                      color: statusColor(row),
                      fontWeight: "600",
                      letterSpacing: "1px",
                    }}
                  >
                    {row.status}
                  </TableCell>
                </WideTableRow>
                <NarrowTable>
                  <Row row={row} />
                </NarrowTable>
                <MidTable>
                  <Row row={row} />
                </MidTable>
              </React.Fragment>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={6}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
