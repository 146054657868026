import Grid from "@material-ui/core/Grid";
import "./signin/FormSignin.css";
import HeaderWhite from "./headerWhite/HeaderWhite";
import React from "react";
import { Link } from "react-router-dom";
import useTabTitle from "../utils/useTabTitle";

const ForgetPassword2 = () => {
  return (
    <div
      className="fgpw-container2"
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <HeaderWhite />
      <Grid item container>
        <Grid item sm={2} xs="auto"></Grid>
        <Grid item sm={8} xs={12}>
          <div
            className="fgpw-form-container"
            style={{
              textAlign: "left",
              width: "80%",
            }}
          >
            <div
              className="content-right"
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "80%",
                paddingTop: "100px",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontSize: "2rem",
                  letterSpacing: "1px",
                  paddingBottom: "20px",
                }}
              >
                Please check your email inbox
              </h1>
              <p>
                We have sent you an forget Password e-mail, please follow the
                link to reset the password.
              </p>
              <Link
                to={`/`}
                style={{
                  display: "block",
                  color: "370075",
                  paddingTop: "40px",
                  paddingBottom: "6px",
                }}
                href="#"
              >
                &lt; Go back
              </Link>
              <div
                className="divider"
                style={{
                  width: "100%",
                  borderBottom: "lightgrey solid 1px",
                  paddingTop: "30px",
                }}
              ></div>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} xs="auto"></Grid>
      </Grid>
    </div>
  );
};

export default ForgetPassword2;
