import React from "react";
import Grid from "@material-ui/core/Grid";
import Background from "../img/background-signup.jpg";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import useTabTitle from "../utils/useTabTitle";
import PTRootUBoldWoff2 from "../font/PT/PT-Root-UI_Bold.woff2";

const Activation = () => {
  useTabTitle("TIPars | Account Activation");
  return (
    <div
      class="activation-container"
      style={{
        textAlign: "left",
      }}
    >
      <Grid
        container
        style={{
          height: "100vh",
        }}
      >
        <Grid item sm={4} xs="auto">
          <div
            class="content-left"
            style={{
              backgroundImage: `url(${Background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
              margin: "0",
            }}
          >
            <Link to={`/`}>
              <div
                className="HeaderLeft"
                style={{
                  height: "96px",
                  display: "flex",
                  marginLeft: "8%",
                }}
              >
                <div
                  className="logo"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "20px",
                    float: "right",
                  }}
                >
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      width: "48px",
                      height: "48px",
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: "1.8rem",
                    fontWeight: "900",
                    color: "white",
                    letterSpacing: "",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  TIPars
                </p>
              </div>
            </Link>
          </div>
        </Grid>
        <Grid item sm={8} xs={12}>
          <div
            class="content-right"
            style={{
              display: "block",
              margin: "auto",
              width: "80%",
              paddingTop: "100px",
            }}
          >
            <h1
              style={{
                color: "black",
                fontSize: "2rem",
                letterSpacing: "1px",
                paddingBottom: "20px",
              }}
            >
              Please check your email inbox
            </h1>
            <p>
              Thank you for creating the account. We have sent you an activation
              email, please follow the link to acitivate the account.
            </p>
            <Link
              to={`/`}
              style={{
                display: "block",
                color: "#370075",
                paddingTop: "40px",
                paddingBottom: "6px",
              }}
              href="#"
            >
              &lt; Go back
            </Link>
            <div
              className="divider"
              style={{
                width: "100%",
                borderBottom: "lightgrey solid 1px",
                paddingTop: "30px",
              }}
            ></div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Activation;
