import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PTRootUIRegular from "../font/PT/PT-Root-UI_Regular.woff2";
import PTRootUIMedium from "../font/PT/PT-Root-UI_Medium.woff2";
import PTRootUILight from "../font/PT/PT-Root-UI_Light.woff2";
import PTRootUIBold from "../font/PT/PT-Root-UI_Bold.woff2";

const Regular = {
  fontFamily: "PTRootUIRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('PTRootUIRegular'),
    url(${PTRootUIRegular}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const Light = {
  fontFamily: "PTRootUILight",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('PTRootUILight'),
    url(${PTRootUILight}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const Bold = {
  fontFamily: "PTRootUIBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('PTRootUIBold'),
    url(${PTRootUIBold}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const Medium = {
  fontFamily: "PTRootUIMedium",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('PTRootUIMedium'),
    url(${PTRootUIMedium}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#370075",
      dark: "#233132",
      light: "#98C1D9",
    },
    secondary: {
      main: "#F87700",
      dark: "#A53327",
      light: "#F1AE8B",
    },
    texyPrimary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: "Medium, Regular, Light, Bold",
    h3: {
      fontFmaily: "Bold",
      fontSize: "2rem",
    },
  },
});
export default function CustomStyles() {
  return <ThemeProvider theme={theme}> </ThemeProvider>;
}
