import "../../App.css";
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import LandingContent from "./LandingContent";
import background from "../../img/background.png";
import LandingCite from "./LandingCite";
import { Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../Footer";
import theme from "../../Theme";
import LoginPrompt from "./LoginPrompt";
import "../../styles/home.css";
import HeaderRightLogined from "./HeaderRightLogined";
import useTabTitle from "../../utils/useTabTitle";
import TiparsEntry from "./TiparsEntry.js";
import { propsToClassKey } from "@mui/styles";

const Home = ({ isSignedIn, userName }) => {
  useTabTitle("TIPars");
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="home">
        <div
          item
          container
          style={{
            backgroundImage: `url(${background})`,
            backgroundColor: "#370075",
            backgroundSize: "cover",
            width: "100%",
            paddingBottom: "80px",
          }}
        >
          <div
            className="home-header"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "16.7%",
              }}
            >
              <HeaderLeft />
            </div>
            <div
              style={{
                width: "66.7%",
              }}
            ></div>
            <div
              style={{
                width: "16.6%",
              }}
            >
              <Switch>
                {isSignedIn ? <HeaderRightLogined /> : <HeaderRight />}
                {/* <Route path="*" component={NoMatch} /> */}
              </Switch>
            </div>
          </div>
          <LandingContent />
        </div>
        <div className="landing-login-model">
          <Switch>
            <TiparsEntry userName={userName}/> 
            {/* <Route path="*" component={NoMatch} /> */}
          </Switch>
        </div>
        <LandingCite />
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return { 
    isSignedIn: state.auth.isSignedIn,
    userName : state.auth.name,
  
  
  };
};
export default connect(mapStateToProps)(Home);
