import { React, useState } from "react";
import { Link } from "react-router-dom";
import tired from "../img/tired.svg";
import { Redirect } from "react-router-dom";
import useTabTitle from "../utils/useTabTitle";

const UnclearError = () => {
  useTabTitle("TIPars | Error");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [second, setSecond] = useState(5);
  const fiveSecondRedirect = setInterval(() => {
    setSecond(second - 1);
    if (second === 0) {
      setShouldRedirect(true);
      clearInterval(fiveSecondRedirect);
    }
  }, 1000);
  if (shouldRedirect) return <Redirect to="/" />;
  return (
    <div
      tw-container
      style={{
        backgroundColor: "#98ACAF",
        height: "100vh",
        position: "absolute",
        width: "100%",
      }}
    >
      <div
        className="tw-paper"
        style={{
          width: "686px",
          height: "686px",
          margin: "auto",
          position: "relative",
          top: "50%",
          marginTop: "-343px",
          borderRadius: "2px",
          backgroundColor: "#FFF",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
        }}
      >
        <img
          alt="tired"
          src={tired}
          style={{
            marginTop: "60px",
          }}
        />
        <h1
          style={{
            color: "black",
            fontSize: "2rem",
            letterSpacing: "1px",
            marginTop: "50px",
            marginBottom: "20px",
          }}
        >
          Oops! Unclear error occured.
        </h1>
        <span>Please go back to EiGEN and try again.</span>
        <div
          style={{
            marginTop: "50px",
          }}
        >
          <Link to={`/`}>&lt; Go to EiGEN</Link>
          <p
            style={{
              color: "grey",
              marginTop: "10px",
            }}
          >
            The page will jump after {second} second.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnclearError;
