import React from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "../../Theme";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const LocationDropdown = (props) => {
  const classes = useStyles();
  // console.log(props.locationCountry);

  const handleLocationChange = (e, data) => {
    // props.enableSave(save);
    // console.log(data);
    if (data) {
      props.onChange(data.value)
      // props.selectedCountry(data.value);
      if (props.switchLocation) {
        props.switchLocation();
      }
    }
  };

  if (props.isSignedIn) {
    return (
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Autocomplete
          getOptionSelected={(option, value) => option.value === value.value}
          id="country-select-demo"
          onChange={handleLocationChange}
          options={props.countries}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              className="profile-location"
              {...params}
              placeholder={props.locationCountry}
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              size="small"
            />
          )}
        />
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Autocomplete
          getOptionSelected={(option, value) => option.value === value.value}
          id="country-select-demo2"
          onChange={handleLocationChange}
          options={props.countries}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose your location"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              size="small"
            />
          )}
        />
      </ThemeProvider>
    );
  }
};
export default LocationDropdown;
