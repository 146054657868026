import React from "react";
// import { GoogleLogout } from "react-google-login";
import { signIn, signOut } from "../../actions";
import { connect } from "react-redux";
import { deleteState, saveLocalStorage } from "../localStorage";
import { OAUTH_CLIENT_KEY } from "../../contants/keys"
import * as DisplayedMessages from '../DisplayedMessages'

const clientId = OAUTH_CLIENT_KEY



const Logout = (props) => {
    const onClick = () => {
        // const fetchLogout = async () => {
        //   try {
        //     await fetch(`http://localhost:8000/users/${props.userId}/logout`, {
        //       headers: {
        //         'Content-Type': 'application/json',
        //         "Authorization": `Token ${props.token}`
        //       },
        //       method: 'POST'
        //     }).then(res => res.json())
        //       .then(response => {
        //         console.log(response)
        //         console.log("Logout made successfully")
        //       })
        //   } catch (err) {
        //     if (err.message === "Failed to fetch") {
        //       console.log(DisplayedMessages.CONNECTION_ERROR)
        //     } else {
        //       console.log(err.message)
        //     }
        //   }
        // };
        props.signOut();
        deleteState();
        // fetchLogout();
        saveLocalStorage(false);
        
    };

    const onFailure = (err) => {
        // console.log(err);
    };

    return (
        <button
            onClick={onClick}
            disabled={false}
            style={
                {
                    border: "none",
                    background: "none",
                    padding: "0",
                    width: "100%",
                    display: "block",
                    textAlign: "left",
                    alignItems: "left",
                    marginTop: "10px",
                    marginBottom: "10px",
                }
            } >
            <div className="logout-btn" >
                <span style={
                    {
                        margin: "auto",
                        color: "#F87700",
                        fontSize: "1rem",
                        fontFamily: "PTRootUIWebMedium",
                    }
                } >
                    Logout </span> </div> </button>
    )
};

export default connect(null, { signIn, signOut })(Logout);

// <div>
// <GoogleLogout clientId = { clientId }
// buttonText = "Logout with Google"
// onLogoutSuccess = { onSuccess }
// onFailure = { onFailure }
// render = {
//     (renderProps) => (
//     <button
//         onClick={renderProps.onClick }
//         disabled={renderProps.disabled }
//         style={
//             {
//                 border: "none",
//                 background: "none",
//                 padding: "0",
//                 width: "100%",
//                 display: "block",
//                 textAlign: "left",
//                 alignItems: "left",
//                 marginTop: "10px",
//                 marginBottom: "10px",
//             }
//         } >
//         <div className = "logout-btn" >
//         <span style = {
//             {
//                 margin: "auto",
//                 color: "#F87700",
//                 fontSize: "1rem",
//                 fontFamily: "PTRootUIWebMedium",
//             }
//         } >
//         Logout </span> </div> </button>
//     )
// }
// /> </div>
// );