import React from "react";
import { Divider } from "@material-ui/core";
import facebook from "../../img/facebook-grey.svg";
import github from "../../img/github-grey.svg";
import linkedin from "../../img/linkedin-grey.svg";

function OurTeamContent(props) {
  let info = props.info;
  // console.log(props.info);
  return (
    <div className="member-card">
      <div className="member-img-container">
        <img alt={info.memberName} src={require("" + info.memberImg).default} />
      </div>
      <div className="member-info-container">
        <div className="member-text-container">
          <h3> {info.memberName} </h3> <span> {info.teamRole} </span>
          <p> {info.memberIntro} </p>
        </div>
        {/* <Divider /> */}
        <div className="member-contact-container">
          {/* <a href={info.faceBook}>
                        <img alt="facebook" src={facebook} />
                      </a>
                      <a href={info.gitHub}>
                        <img alt="github" src={github} />
                      </a>
                      <a href={info.linkedIn}>
                        <img alt="linkedin" src={linkedin} />
                      </a> */}
          <p
            style={{
              color: "#370075",
            }}
          >
            {info.memberEmail1} <br /> {info.memberEmail2}
          </p>
        </div>
      </div>
    </div>
  );
}
export default OurTeamContent;
