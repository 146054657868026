import {BACKENDURL} from "../contants/backendSettings"
const getCountriesList = async () => {
  const response = await fetch(`${BACKENDURL}/users/countries`)
    .then((response) => response.json())
    .catch((err) => {
      // console.log(err)
    });
  return response;
};
export default getCountriesList;
