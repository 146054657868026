import { SIGN_IN, SIGN_OUT, CHANGE_INFO } from "../actions/types";

const INITIAL_STATE = {
    platform: null,
    isSignedIn: null,
    platformId: null,
    Id: null,
    name: null,
    token: null,
    email: null,
    refresh: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {
                ...state,
                platform: action.payload.platform,
                isSignedIn: true,
                platformId: action.payload.platformId,
                Id: action.payload.Id,
                name: action.payload.name,
                token: action.payload.token,
                email: action.payload.email,
                refresh: action.payload.refresh,
                location: action.payload.location,
            };
        case SIGN_OUT:
            return {
                ...state,
                platform: null,
                isSignedIn: null,
                platformId: null,
                Id: null,
                name: null,
                token: null,
                email: null,
                refresh: null,
                location: null,
            };
        case CHANGE_INFO:
            return {
                ...state,
                token: action.payload,
            }
        default:
            return state;
    }
};