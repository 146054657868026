import React from "react";
import uploadButton from "../../icons/uploadButton.png";
import removeButton from "../../icons/removeButton.png";
import disabledButton from "../../icons/disabledButton.png";

export default function Input({
  name,
  selectedFile,
  setSelectedFile,
  label,
  remark,
  formats,
  isValid,
  setIsValid,
  encoding,
  disabled,
  withPastML,
  refType
}) {
  // const randomGenerator = () => {
  //   return Math.random().toString(36).substr(2);
  // };
  // const [open1, setOpen1] = React.useState(false);
  // const [open2, setOpen2] = React.useState(false);
  // const [open3, setOpen3] = React.useState(false);
  // const overSizeClose = () => {
  //   setOpen1(false);
  // };
  // const overSizeOpen = () => {
  //   setOpen1(true);
  // };
  // const onOverSize = () => {
  //   overSizeOpen();
  // };
  // const onInvalidFasta = () => {
  //   setOpen2(true);
  // };
  // const invalidFastaClose = () => {
  //   setOpen2(false);
  // };
  // const fastaDialogOpen = () => {
  //   setOpen3(true);
  // };
  // const fastaDialogClose = () => {
  //   setOpen3(false);
  // };

  // bug: fileOnChange does not fire when we upload  the same file on the same field subsequently !!!!!

  const validation = (fileName, formats) => {
    const pattern = /(.*?)\.(.*)/g;
    if (!fileName) return false;
    const suffix = pattern.exec(fileName)[2];
      for (let i = 0; i < formats.length; i++) {
        if (suffix === formats[i]) return true;
      }
      return false;
    }

  React.useEffect(() => {
    // console.log({ selectedFile, setSelectedFile, label, remark });
  }, []);

  let fileNames=["No file uploaded", ".tree", "_taxa.fas", "_anc.fas"]
  let fileName = "No file uploaded";
  if(refType=="tipars"){
    if (!selectedFile) {
      fileName = "No file uploaded";
    }else{
      fileName = selectedFile.name
    }
  }else{
    if (!selectedFile) {
      if(encoding==0){
        fileName = "No file uploaded"
      }else{
        fileName = refType+fileNames[encoding]
      } 
    }else{
      fileName = selectedFile.name
    }
  }
  const onFileClick = (event) => {
    event.target.value=null
  }
  const onFileChange = (event) => {
    let file;
      file = event.target.files[0];
      setSelectedFile(file);
      console.log(file); 
    // set selected file as the value of 'selectedFile' variable, validate it accordingly and update 'isValid' variable
    const _isValid = validation(file.name, formats);
    if(refType=="tipars"){
      setIsValid(
        [...isValid].map((validValue, index) =>
          index === encoding ? _isValid : validValue
        )
      );
    }else{
    const _isValid = validation(file.name, formats);
    setIsValid([_isValid, true, true, true]);
    };
  }
    
  // const onFileChangeReference = (event) => {
  //   let file;
  //   if(encoding==0){
  //     file = event.target.files[0];
  //     setSelectedFile(file);
  //   }
  //   const _isValid = validation(file.name, formats);
  //   setIsValid([_isValid, true, true, true]);
  // };

  const handleClick = (event) => {
    // console.log("Enter into UploadFile.js (handleClick)");
    if (!selectedFile) {
      hiddenFileInput.current.click();
    } else {
      // set the file as null when you press the button again, so isValid also need to update accordingly
      setSelectedFile(null);
      setIsValid(
        [...isValid].map((validValue, index) =>
          index === encoding ? false : validValue
        )
      );
    }
  };
  const hiddenFileInput = React.useRef(null);
  let textButton = "Upload from local files";
  let iconButton = uploadButton;
  if (selectedFile) {
    textButton = "Remove";
    iconButton = removeButton;
  } else if (disabled) {
    iconButton = disabledButton;
  }
// console.log(refType)
  return (
    <div className="upload-container">
      <label style={{ color: (encoding === 3 && withPastML)||(encoding!==0 && refType!=="tipars") ? "gray" : null }}>
        {label}
      </label>
      <div className="upload-content">
        <p
          style={{
            color: (selectedFile&&refType=="tipars")||(selectedFile&&encoding==0)? "black" : "gray",
          }}
        >
          {fileName}
        </p>
        <button onClick={handleClick} disabled={disabled}>
          <img src={iconButton} alt="" id="uploadIcon" />
        </button>
        <input
          type="file"
          name="file"
          onChange={onFileChange}
          onClick={onFileClick}
          style={encoding === 3 && withPastML ? { display: "none" } : {color :  "gray", display : "none"}}
          ref={hiddenFileInput}
          key={name}
          disabled={disabled}
          
        />
      </div>
      <span style={{ color: (encoding === 3 && withPastML)||(refType!=="tipars") ? "gray" : null }}>
        {remark}
      </span>
      <div></div>
      {(!isValid[encoding] && selectedFile) ? (
        <span style={{ color: "red" }}>Wrong Format</span>
      ) : null}
      {encoding === 3 && withPastML ? (
        <span style={{ color: "red" }}>
          You selected WithPastML mode, therefore this field is not available.
        </span>
      ) : null}
    </div>
  );
}
