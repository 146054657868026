import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import theme from "../Theme";

function getSteps() {
  return ["STEP1", "STEP2", "STEP3", "STEP4"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `Click "Go predict" to the predict page. Choose the right model you want to use.`;
    case 1:
      return "Upload the fasta file or input the sequence fragment and click to preview. ";
    case 2:
      return `In the preview pop-up window, check the uploaded/input content and define the project name, etc., and click Submit after confirming that it is correct.
      Click the link in the pop-up to view the progress and results of the project. (Logged in users can check the progress and results of historical projects can be viewed in the "profile-task center".)`;
    case 3:
      return `
        Click the link in the pop-up to view the progress and results of the project. (Logged in users can check the progress and results of historical projects can be viewed in the "profile-task center".)`;
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper() {
  const steps = getSteps();

  return (
    <ThemeProvider theme={theme}>
      <Stepper orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} active="true">
            <StepLabel>
              <h3>{label}</h3>
            </StepLabel>
            <StepContent>
              <Typography>
                <p
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  {getStepContent(index)}
                </p>
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
  );
}
