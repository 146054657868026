import React from "react";
import { Link } from "react-router-dom";

export default function TiparsEntry({userName}) {
  return (
    <div
      className="tipars-entry-container"
      style={{
        backgroundColor: "#fdba31",
        minHeight: "177px",
      }}
    >
      <div
        className="tipars-entry-content"
      >
        <h3
          style={{
            textAlign: "center",
            paddingTop: "30px",
          }}
        >
          Hello, {userName ? userName.replace("_", " "): "friend"}!
        </h3>
        <Link to="./tipars">
          <button className="primary-button" style={{
            marginRight: "30px"
          }}>Go TIPars></button>
        </Link>
          {userName? 
          <Link to="./taskCenter">
            <button 
            className="secondary-button" style={{
            marginRight: "30px"
            }}>
              Task Center
            </button>
          </Link>: <Link to="./login">
            <button 
            className="secondary-button" style={{
            marginRight: "30px"
            }}>
              Login
              </button>
          </Link>}
      </div>
    </div>
  );
}
