import React from "react";
import "../../styles/core.css";
import processing from "../../icons/processing.png";
import finished from "../../icons/finished.png";
import failed from "../../icons/failed.png";
import deleteIcon from "../../icons/delete.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import history from "../history";
import {
  jobTimeConvertToStr,
  jobTimeDeltaConvertToStr,
} from "../../utils/time";
import { BACKENDURL } from "../../contants/backendSettings";
import axios from "axios";
import jwtAuthentication from "../../utils/jwtAuthentication";
import { connect } from "react-redux";

const TaskCard = (props) => {
  let data = props.data;
  console.log(data);
  const [dateState, setDateState] = React.useState(new Date());
  React.useEffect(() => {
    const interval = setInterval(() => setDateState(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  const submitTime = jobTimeConvertToStr(data.created);
  let costTime = jobTimeDeltaConvertToStr(
    data.duration,
    dateState,
    data.created,
    data.status
  );

  const projectName = data.project_name;
  const status = data.status;

  const query_seq_file_name = data.query_seq_file_name;
  const tree_file_name = data.tree_file_name;
  const taxa_seq_file_name = data.taxa_seq_file_name;
  const anc_seq_file_name = data.anc_seq_file_name;
  let buttonStatus;
  let iconStatus;
  let interruptDisplay;
  let deleteDisplay;
  let resultDisplay;
  let disabled = true;
  if (data.status == "SUCCESS") {
    buttonStatus = "primary-button";
    iconStatus = finished;
    interruptDisplay = "none";
    deleteDisplay = "auto";
    resultDisplay = "auto"
    disabled = false;
  } else if (data.status == "FAILED") {
    interruptDisplay = "none";
    buttonStatus = "disabled-button";
    iconStatus = processing;
    deleteDisplay = "auto";
    resultDisplay = "auto"
    disabled = true;
  } else if (data.status === "CANCELLED")  {
    buttonStatus = "disabled-button";
    iconStatus = processing;
    interruptDisplay = "none";
    deleteDisplay = "none";
    resultDisplay = "none"
    disabled = true;
  } else {
    buttonStatus = "disabled-button";
    iconStatus = processing;
    interruptDisplay = "auto";
    deleteDisplay = "none";
    resultDisplay = "auto"
    disabled = true;
  }

  const fetchDataDelete = async (dataID, accessToken = null) => {
    let fetchMeta = {}
    if (accessToken) {
      fetchMeta = {
        headers : {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    try {
      const response = await axios.delete(`${BACKENDURL}/jobs/${dataID}/`, fetchMeta)
      // console.log("request's response", response)
      if (response.status !== 204) {
        throw new Error("Something went wrong when trying to delete job")
      }
      return {state : true, response : response}
    } catch (err) {
      // console.log("Please try again later")
      return {state : false, response : undefined}
    }
  }
  const fetchDataInterrupt  = async (dataID, accessToken = null) => {
    let fetchMeta = {}
    if (accessToken) {
      fetchMeta = {
        headers : {
          Authorization: `Bearer ${accessToken}`
        }
      }
    }
    try {
      const response = await axios.post(`${BACKENDURL}/jobs/cancel/${dataID}/`, {}, fetchMeta)
      if (response.status !== 200) {
          throw new Error("something went wrong when trying to cancel job")
        }
      return {state : true, response : response}
      } catch (err) {
        // console.log(err.message)
        return {state : false, response : undefined}
      }
    }

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const onDelete = (e) => {
    (async function() {
      const functionResponse = await jwtAuthentication((accessToken) => fetchDataDelete(data.id, accessToken),props.token, props.isSignedIn, props.refreshToken)
      const {state, response} = functionResponse
      if (state) {
        props.onTaskDelete()
      }
    })()
    handleDeleteClose()
    // (async function() {
    //   try {
    //     if (data.id) {
    //       const response = await axios.delete(`${BACKENDURL}/jobs/${data.id}`)
    //       if (response.status !== 204) {
    //         throw new Error("Something went wrong when trying to delete job.")
    //       }
    //       props.onTaskDelete()
    //     }
    //   } catch (error) {
    //     console.log("please try again later")
    //   }
    // })()
    // handleDeleteClose()
  };

  const onInterrupt = (e) => {
    (async function() {
      const functionResponse = await jwtAuthentication((accessToken) => fetchDataInterrupt(data.id, accessToken),props.token, props.isSignedIn, props.refreshToken)
      const {state, response} = functionResponse
      if (state) {
        props.onTaskDelete()
      }
    })()
    handleInterruptClose()

    //   (async function() {
    //     try {
    //     console.log("enter into onInterrupt")
    //     const response = await axios.post(`${BACKENDURL}/jobs/cancel/${data.id}/`)
    //     if (response.status !== 200) {
    //       throw new Error("Something went wrong when trying to cancel the job")
    //     }
    //     const {status} = response.data
    //       // do some stuff on frontend and change the UIUX
    //     props.onTaskDelete()
    //   } catch (e) {
    //     console.log(e.message)
    //   }
    //   })()

    // handleInterruptClose()
  } 

  const handleInterruptOpen = () => {
    setOpen(true);
  };
  const handleInterruptClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setOpen1(true);
  };
  const handleDeleteClose = () => {
    setOpen1(false);
  };

  const handleOnClick = (e) => {
    // console.log("(TaskCard.js) handleOnClick", data);
    e.preventDefault();
    const id = data.id
    if (id) {
      // console.log("This is TaskCard Info: ", id);
      history.push(`/result/${id}`);
    } else {
    }
  };

  return (
    <div className="task-card">
      <div className="time-container">
        <p>
          Submitted at <span>{submitTime}</span>
        </p>
        <p
          style={{
            textAlign: "right",
          }}
        >
          Time:&nbsp;
          <span>{costTime}</span>
        </p>
      </div>
      <div className="model-status-container">
        <h3>{projectName}</h3>
        <div className="status-container">
          <img src={iconStatus} alt="" />
          <p>{status}</p>
        </div>
      </div>
      <div className="id-file-coantiner">
        <p>
          Query Sequence: <span>{query_seq_file_name}</span>
        </p>
        <p>
          Reference Tree: <span>{tree_file_name}</span>
        </p>
        <p>
          TaxaMSA: <span>{taxa_seq_file_name}</span>
        </p>
        <p>
          Ancestral Sequence Alignment: <span>{anc_seq_file_name?anc_seq_file_name:"NA"}</span>
        </p>
      </div>
      <div className="result-button-container">
        <button
          className="dangerous-button"
          id="task-card-interrupt"
          style={{
            marginTop: "0",
            display: interruptDisplay,
          }}
          onClick={handleInterruptOpen}
        >
          Cancel
        </button>
        <button
          style={{
            marginTop: "0",
            marginRight: "20px",
            display: deleteDisplay,
          }}
          onClick={handleDeleteOpen}
        >
          <img src={deleteIcon} alt="delete" />
        </button>
        <button
          className={buttonStatus}
          style={{
            margin: "0",
            display : resultDisplay
          }}
          onClick={handleOnClick}
          disabled={disabled}
        >
          Result
        </button>
      </div>
      <Dialog
        open={open}
        onClose={handleInterruptClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to cancel this task?"}
        </DialogTitle>
        <DialogContent>
          <p>
            Cancellation is irreversible and you will lose all records of this
            task.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="secondary-button"
            onClick={handleInterruptClose}
            style={{
              marginRight: "10px",
            }}
          >
            Back
          </button>
          <button className="primary-button" onClick={onInterrupt} autoFocus>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete the task?"}
        </DialogTitle>
        <DialogContent>
          <p>
            Deletion is irreversible and you will lose all records of this task.
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="secondary-button"
            onClick={handleDeleteClose}
            style={{
              marginRight: "10px",
            }}
          >
            Back
          </button>
          <button className="primary-button" onClick={onDelete} autoFocus>
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(TaskCard);

