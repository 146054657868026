import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import reduxThunk from "redux-thunk";
import theme from "./Theme";
import { NODE_ENV } from './contants/mode'
import { OAUTH_CLIENT_KEY } from './contants/keys'
import {
  loadState,
  saveState,
  loadLocalStorage,
  saveLocalStorage,
} from "./components/localStorage";
import { GoogleOAuthProvider } from '@react-oauth/google';

let persistedState;
if (loadLocalStorage()) {
  persistedState = { ...loadState(), localStorage: true };
} else {
  persistedState = {
    auth: {
      isSignedIn: null,
      token: null,
      refresh: null,
      platform: null,
      platformId: null,
      Id: null,
      name: null,
      email: null,
    },
    localStorage: false,
  };
}

const composeEnhancers =  compose;
const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(reduxThunk))
);

store.subscribe(() => {
  // console.log(store.getState());
  if (store.getState().localStorage) {
    if (store.getState().auth.isSignedIn) {
      saveState(store.getState().auth);
    }
    saveLocalStorage(true);
  } else {
    saveLocalStorage(false);
  }
});
ReactDOM.render(
  <>
  <GoogleOAuthProvider clientId={OAUTH_CLIENT_KEY} >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App store={store} />
      </ThemeProvider>
    </Provider>
  </GoogleOAuthProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
