import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../Theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className={classes.heading}>1. Can I run TIPars without creating an account? </h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>
            Yes you can! URL to the result page will be sent to your email once ready. 
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h4 className={classes.heading}>2. What's the benefit of creating a Login account?</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>
            Your results will be stored in Task Center, which is a login only feature 😃
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <h4 className={classes.heading}>3. Can I share my result tree to others by simply giving out the result page URL?</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>
            Yes, you can! This is feature, not a bug 😁
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <h4 className={classes.heading}>4. My TIPars run returned Error, what should I do? 🤔</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>
            Please check for the following errors. In our experience, they are the most common:<br/>
            <br/>
            - Reference Tree is not rooted<br/>
            - Incorrect files supplied (e.g. mixing 'query.fas' with 'treeMSA.fas')<br/>
            - Forgetting to click the 'run MAFFT' option when Query Sequences file is not aligned to Tree MSA<br/>
            - Using the wrong Reference Tree (e.g. aligning SARS-Cov2 sequences to Influenza-A-H5)<br/>
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </ThemeProvider>
  );
}
