import generateAccessToken from "./GenAccToken"
import {ChangeInfo} from '../actions'

const jwtAuthentication = async (callBack, initialToken, isSignedIn, refreshKey) => {
    // the callback should have format (token) and return {state : true/false, data : {}}
    // callBack is the function of sending main request to the backend
    // afterCallBack is the function taking argument accessToken
    try {
        let state;
        let response;
        let token = initialToken;
        if (!isSignedIn) {
            for (var i = 0; i < 3; i++) {
                let temp = await callBack()
                return { state : true, response : temp.response }
            }
            return { state: false, response: {}}
        } else {
            for (var i = 0; i < 3; i++) {
                let temp = await callBack(token)
                console.log("temp: ", temp)
                state = temp.state
                response = temp.response
                if (state) break 
                token = await generateAccessToken(isSignedIn, refreshKey)
                console.log("token: ", token)
                if (!token) return {state: false, response : {}}
            }
            ChangeInfo(token);
            return { state , response }
        }
    } catch (err) {
        console.err(err)
        return {state : false, response : {}}
    }

}

export default jwtAuthentication