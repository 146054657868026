import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import VerticalLinearStepper from "./VerticalLinearStepper";
import blank from "../img/feature.png";
import Footer from "./Footer";
import useTabTitle from "../utils/useTabTitle";

const Tutorial = () => {
  useTabTitle("TIPars | Tutorial");
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item sm xs></Grid>
          <Grid item sm={8} xs={12}>
            <div className="external-pages">
              <h2>HOW IT WORKS</h2>
              <h1>
                Start TIPars Quikly in <span>X</span> Steps
              </h1>
              <div className="text-button-container">
                <div className="text-container-l">
                  <p>
                    You have completed all the steps of registration, you are
                    welcome! You have completed all the steps of registration,
                    you are welcome!You have completed all the steps of
                    registration, you are welcome! You have completed all the
                    steps of registration, you are welcome!
                  </p>
                </div>
                <button className="form-input-btn">Tutorial Video</button>
              </div>
              <Divider
                style={{
                  marginTop: "36px",
                }}
              />
              <div className="tutorial-content-container">
                <div className="tutorial-content-left">
                  <VerticalLinearStepper />
                </div>
                {/* <div className="tutorial-content-right">
                  <img
                    alt="tutorial"
                    src={blank}
                    style={{
                      width: "100%",
                    }}
                  />
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid item sm xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Tutorial;
