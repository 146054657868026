import Grid from "@material-ui/core/Grid";
import "./signin/FormSignin.css";
import { React, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import useTabTitle from "../utils/useTabTitle";

const ForgetPassword3 = () => {
  useTabTitle("TIPars | Forget Password");
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [second, setSecond] = useState(5);
  const fiveSecondRedirect = setInterval(() => {
    if (second <= 0) {
      setShouldRedirect(true);
      clearInterval(fiveSecondRedirect);
    } else {
      setSecond(second - 1);
    }
  }, 1000);
  if (shouldRedirect) return <Redirect to="/login" />;
  return (
    <div className="fgpw-container3">
      <Grid item container>
        <Grid item sm={2} xs="auto"></Grid>
        <Grid item sm={8} xs={12}>
          <div
            className="rspw-form-container"
            style={{
              marginTop: "10%",
              borderRadius: "2px",
              padding: "20px",
              paddingBottom: "36px",
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              boxShadow: "0px 2px 24px rgba(0, 0, 0, 0.06)",
            }}
          >
            <div
              className="content-right"
              style={{
                display: "block",
                height: "100%",
                margin: "auto",
                width: "80%",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontSize: "2rem",
                  letterSpacing: "1px",
                  paddingBottom: "20px",
                }}
              >
                Your Password Has Successfully Reset!
              </h1>
              <p> Please log in with the new password. </p>
              <div
                className="divider"
                style={{
                  width: "100%",
                  borderBottom: "lightgrey solid 1px",
                  paddingTop: "30px",
                }}
              ></div>
              <Link
                to={`/login`}
                style={{
                  display: "block",
                  color: "#370075",
                  paddingTop: "40px",
                  paddingBottom: "6px",
                }}
                href="#"
              >
                Login
              </Link>
              <p
                style={{
                  color: "grey",
                }}
              >
                The page will jump in {second} second.
              </p>
            </div>
          </div>
        </Grid>
        <Grid item sm={2} xs="auto"></Grid>
      </Grid>
    </div>
  );
};

export default ForgetPassword3;
