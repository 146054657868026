import React, { useEffect, useState, useRef } from "react";
import HeaderWhite from "../headerWhite/HeaderWhite";
import Input from "./Input";
import { connect } from "react-redux";
import Preview from "./Preview";
import OptionDropdown from "./OptionDropdown";
import TiparsDrawer from "./TiparsDrawer";
import history from "../history";
// let withPastML = false;

const Reference = (props) => {
  // async function downloadFile() {
  //   let serverStatus = await fetch("/static/media/anc.93abb1b3.fasta")
  //   if (serverStatus.status != 200) {
  //     throw new Error("Server Error");
  //   }
  //   let anc = await fetch("/static/media/anc.93abb1b3.fasta")
  //   // let anc = await fetch(`${BACKENDURL}/ref-trees/${refType}/anc.fasta`);
  //   .then((i)=>i.blob())
  //   .then((blob)=>{
  //     blob.name="anc.fasta"
  //     blob.lastModified = new Date()
  //     const ancFile = new File([blob], 'anc.fasta', {type:blob.type})
  //     setSelectedFile3(ancFile)
  //   })
  //   let tree = await fetch("/static/media/tree.1f1fe6b9.nwk")
  //   .then((i)=>i.blob())
  //   .then((blob)=>{
  //     blob.name="tree.nwk"
  //     blob.lastModified = new Date()
  //     const treeFile = new File([blob], 'anc.fasta', {type:blob.type})
  //     setSelectedFile3(treeFile)
  //   })
  //   let taxa = await fetch("/static/media/taxa.32a7935e.fas")
  //   .then((i)=>i.blob())
  //   .then((blob)=>{
  //     blob.name="taxa.fasta"
  //     blob.lastModified = new Date()
  //     const taxaFile = new File([blob], 'taxa.fasta', {type:blob.type})
  //     setSelectedFile3(taxaFile)
  //   })
  // }
  
  const [selectedFile0, setSelectedFile0] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [open, setOpen] = useState(false);
  const refTitle  =
  window.location.href.split("/")[window.location.href.split("/").length - 1].replaceAll("_"," ");
  let refType;
  if(refTitle=="Influenza-A-H5 HA Tree"){
    refType="h5"
  }if(refTitle=="SARS-Cov-2 Full Genome Tree"){
    refType="sars-cov-2"
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickEmpty = () => {
    setSelectedFile0(null);
  };

  // enforcing login
  // useEffect(() => {
  //   if (!props.isSignedIn) {
  //     history.push("/");
  //   }
  // }, []);

  const isFirstRenderSelectedOption0State = useRef(true);

  const validFormats =
["fasta", "fas", "fna", "ffn", "faa", "frn", "fa", "vcf"];

const rows = [
  {
    name: "querySeq",
    label: "Query Sequences",
    remark: "Sequences to be insert to Reference Tree (.fasta)",
    selectedFile: selectedFile0,
    setSelectedFile: setSelectedFile0,
    formats: validFormats,
    encoding: 0,
  },
  {
    name: "referenceTree",
    label: "Rooted Reference Tree",
    remark: "The tree which new sample shall be inserted to (.nwk)",
    selectedFile: selectedFile1,
    setSelectedFile: setSelectedFile1,
    formats: ["nwk"],
    encoding: 1,
  },
  {
    name: "taxaSeq",
    label: "Tree MSA (Multiple Sequence Alignment)",
    remark:
      "The multiple sequence alignment of taxa that built the Reference Tree (.fasta)",
    selectedFile: selectedFile2,
    setSelectedFile: setSelectedFile2,
    formats: validFormats,
    encoding: 2,
  },
  {
    name: "ancSeq",
    label: "Ancestral Sequence Alignment",
    remark:
      "Ancestral sequence (aligned) obtained from PastML or TreeTime (.fasta)",
    selectedFile: selectedFile3,
    setSelectedFile: setSelectedFile3,
    formats: validFormats,
    encoding: 3,
  },
]

  let buttonStatus =
    selectedFile0?true:false

  const [isValid, setIsValid] = useState(rows.map((_) => false));

  // keep tracking with_past_ml option, if with_past_ml === "Yes" (a.k.a selectedOption0 === "true"), we change the corresponding element in
  // isValid array as true, so we can keep the validation process, we also need to setSelectedFile3 as null
  // useEffect(() => {
  //   downloadFile()
  // }, [refType]);

  console.log("isValid: ", isValid);
  console.log(rows)

  let allIsValid = isValid.every((booleanValue) => booleanValue);
  let buttonClass =
    buttonStatus && allIsValid ? "primary-button" : "disabled-button";


  return (
    <div>
      <HeaderWhite />
      <div className="tipars-content-container">
        <TiparsDrawer/>
        <div className="tipars-container">
          <div
            className="tipars-title-container"
            style={{
              borderBottom: "1px lightGrey solid",
            }}
          >
            <h1>{refTitle}</h1>
          </div>
          <div className="advanced-options-container">
            {/*<label>Advanced Options</label>*/}
          </div>
          {rows.map((a, i) => (
            <Input
              {...a}
              isValid={isValid}
              setIsValid={setIsValid}
              disabled={a.encoding==0? false : true}
              withPastML={false}
              refType={refType}
            />
          ))}
            <div className="advanced-options">
            </div>
          <div className="submit-button-container">
            <button
              className="secondary-button"
              style={{
                marginRight: "20px",
              }}
              onClick={handleClickEmpty}
            >
              Empty
            </button>
            <button
              className={buttonClass}
              disabled={!(buttonStatus && allIsValid)}
              onClick={handleClickOpen}
            >
              Continue
            </button>
          </div>
        </div>
        <Preview
          handleClose={handleClose}
          open={open}
          rows={rows}
          dropdowns={null}
          refType={refType}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(Reference);
