import React from "react";
import HeaderWhite from "./headerWhite/HeaderWhite";
import { Grid, Divider } from "@material-ui/core";
import Footer from "./Footer";
import feature1 from "../icons/tolerance.svg";
import feature2 from "../icons/accuracy.svg";
import feature3 from "../icons/efficiency.svg";
import useTabTitle from "../utils/useTabTitle";

const About = () => {
  useTabTitle("TIPars | About");
  return (
    <div>
      <div className="external-pages-container">
        <HeaderWhite />
        <Grid item container>
          <Grid item md={2} sm={2} xs></Grid>
          <Grid item md={8} sm={8} xs={12}>
            <div className="external-pages">
              <div className="text-container-m">
                <h2>ABOUT</h2>
                <h1>What Does TIPars Do?</h1>
                <div className="text-button-container">
                  <p>
                    TIPars is JAVA program to do fast and accurate insertion or
                    placement of new samples onto a reference phylogenetic tree
                    based on parsimony criterion and utilized the pre-computed
                    ancestral sequences. It is reliable for phylogenies comprise
                    both densely sampled sequences (very short tree branches),
                    e.g. SARS-CoV-2 genomes and Influenza viruses, and divergent
                    sequences (long tree branches), e.g. bacterial 16S ribosomal
                    RNA sequences and Newcastle disease virus. It uses BEAST
                    library
                    <span
                      style={{
                        color: "#370075",
                      }}
                    >
                      [1]
                    </span>
                    and requires taxa sequences, the reference tree, and
                    ancestral sequences of all its internal nodes as input data.
                    Reference tree is maintained unchanged (except that a new
                    branch added) in the insertion process. TIPars can do
                    insertion of single or multiple new sequences with Newick
                    format tree file as output, but can also obtain phylogenetic
                    placement of the query sequences by generating Jplace format
                    file
                    <span
                      style={{
                        color: "#370075",
                      }}
                    >
                      [2]
                    </span>
                    for other downstream analyses.
                  </p>
                </div>
              </div>
              <div className="external-page-divider" />
              <div className="text-container-m">
                <p>
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#370075",
                    }}
                  >
                    [1]
                  </span>
                  Suchard, M.A., et al. Bayesian phylogenetic and phylodynamic
                  data integration using BEAST 1.10. Virus evolution, 2018.
                  <br />
                  <br />
                  <span
                    style={{
                      color: "#370075",
                    }}
                  >
                    [2]
                  </span>
                  Matsen, F.A., R.B. Kodner, and E.V. Armbrust, pplacer: linear
                  time maximum-likelihood and Bayesian phylogenetic placement of
                  sequences onto a fixed reference tree. BMC Bioinformatics,
                  2010. 11(1): p. 538.
                </p>
              </div>
              {/* <div className="icon-feature-container">
                <div className="icon-feature">
                  <img alt="feature1" src={feature1} />
                  <p>Tolerance</p>
                </div>
                <div className="icon-feature">
                  <img alt="feature2" src={feature2} />
                  <p>Accuracy</p>
                </div>
                <div className="icon-feature">
                  <img alt="feature3" src={feature3} />
                  <p>Efficiency</p>
                </div>
              </div> */}
            </div>
          </Grid>
          <Grid item md sm={2} xs></Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};
export default About;
