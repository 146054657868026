export const loadState = () => {
    try {
        const serializedState = window.window.localStorage.getItem("state");
        const serializedTime = window.localStorage.getItem("timestamp");
        if (serializedState === null) {
            return undefined;
        }
        const parsedTime = parseFloat(serializedTime);
        if (Date.now() - parsedTime > 24 * 3600 * 1000) {
            deleteState();
            deleteLocalStorage();
            return {
                auth: {
                    isSignedIn: null,
                    token: null,
                    refresh: null,
                    platform: null,
                    platformId: null,
                    Id: null,
                    name: null,
                    email: null,
                },
            };
        } else {
            const parsedState = JSON.parse(serializedState);
            return {
                auth: {
                    isSignedIn: parsedState.isSignedIn,
                    token: parsedState.token,
                    refresh: parsedState.refresh,
                    platform: parsedState.platform,
                    platformId: parsedState.platformId,
                    Id: parsedState.Id,
                    name: parsedState.name,
                    email: parsedState.email,
                },
            };
        }
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        window.window.localStorage.setItem("state", serializedState);
        window.window.localStorage.setItem("timestamp", Date.now().toString());
    } catch (err) {
        // console.log(err);
    }
};

export const deleteState = () => {
    try {
        window.window.localStorage.removeItem("state");
        window.window.localStorage.removeItem("timestamp");
    } catch (err) {
        // console.log(err);
    }
};

export const loadLocalStorage = () => {
    try {
        let booleanValue = window.window.localStorage.getItem("localStorage");
        if (booleanValue === null) {
            return undefined;
        }
        booleanValue = JSON.parse(booleanValue.toLowerCase());
        console.log(booleanValue);
        return booleanValue;
    } catch (err) {
        // console.log(err);
    }
};

export const saveLocalStorage = (state) => {
    try {
        window.window.localStorage.setItem("localStorage", state);
    } catch (err) {
        // console.log(err);
    }
};

export const deleteLocalStorage = () => {
    try {
        window.window.localStorage.removeItem("localStorage");
    } catch (err) {
        // console.log(err);
    }
};