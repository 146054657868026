import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "98%",
    marginTop: "40px",
    textAlign: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  border: {
    borderBottom: "1px solid lightgrey",
    width: "100%",
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 200,
    fontSize: 12,
    color: "lightgray",
    width: "30%",
  },
}));

const Divider = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border}></div>
      <span className={classes.content}> Or </span>
      <div className={classes.border}> </div>
    </div>
  );
};
export default Divider;
