import React, { useEffect, useState, useRef } from "react";
import HeaderWhite from "../headerWhite/HeaderWhite";
import Input from "./Input";
import { connect } from "react-redux";
import Preview from "./Preview";
import OptionDropdown from "./OptionDropdown";
import TiparsDrawer from "./TiparsDrawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import history from "../history";
// let withPastML = false;

const Tipars = (props) => {
  const [selectedFile0, setSelectedFile0] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedOption0, setSelectedOption0] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickEmpty = () => {
    setSelectedFile0(null);
    setSelectedFile1(null);
    setSelectedFile2(null);
    setSelectedFile3(null);
    setSelectedOption0(false);
    setSelectedOption1(false);
  };

  // enforcing login
  // useEffect(() => {
  //   if (!props.isSignedIn) {
  //     history.push("/");
  //   }
  // }, []);

  const isFirstRenderSelectedOption0State = useRef(true);

  const validFormats =
    selectedOption0 === true
      ? ["fasta", "fas", "fna", "ffn", "faa", "frn", "fa"]
      : ["fasta", "fas", "fna", "ffn", "faa", "frn", "fa", "vcf"];

  const rows = [
    {
      name: "querySeq",
      label: "Query Sequences",
      remark: "Sequences to be insert to Reference Tree (.fasta)",
      selectedFile: selectedFile0,
      setSelectedFile: setSelectedFile0,
      formats: validFormats,
      encoding: 0,
    },
    {
      name: "referenceTree",
      label: "Rooted Reference Tree",
      remark: "The tree which new sample shall be inserted to (.nwk)",
      selectedFile: selectedFile1,
      setSelectedFile: setSelectedFile1,
      formats: ["nwk"],
      encoding: 1,
    },
    {
      name: "taxaSeq",
      label: "Tree MSA (Multiple Sequence Alignment)",
      remark:
        "The multiple sequence alignment of taxa that built the Reference Tree (.fasta)",
      selectedFile: selectedFile2,
      setSelectedFile: setSelectedFile2,
      formats: validFormats,
      encoding: 2,
    },
    {
      name: "ancSeq",
      label: "Ancestral Sequence Alignment",
      remark:
        "Ancestral sequence (aligned) obtained from PastML or TreeTime (.fasta)",
      selectedFile: selectedFile3,
      setSelectedFile: setSelectedFile3,
      formats: validFormats,
      encoding: 3,
    },
  ];
  function handleCheckBox0(event) {
    setSelectedOption0(event.target.checked);
  }
  function handleCheckBox1(event) {
    setSelectedOption1(event.target.checked);
  }

  const dropdowns = [
    {
      selectedOption: selectedOption0,
      label: "with_past_ml",
      handleCheckBox: handleCheckBox0,
    },
    {
      selectedOption: selectedOption1,
      label: "run MAFFT ",
      handleCheckBox: handleCheckBox1,
    },
  ];

  let buttonStatus =
    selectedOption0 === true
      ? selectedFile0 && selectedFile1 && selectedFile2 && selectedOption0
        ? true
        : false
      : selectedFile0 && selectedFile1 && selectedFile2 && selectedFile3
      ? true
      : false;

  const [isValid, setIsValid] = useState(rows.map((_) => false));

  // keep tracking with_past_ml option, if with_past_ml === "Yes" (a.k.a selectedOption0 === true), we change the corresponding element in
  // isValid array as true, so we can keep the validation process, we also need to setSelectedFile3 as null
  useEffect(() => {
    if (isFirstRenderSelectedOption0State.current) {
      isFirstRenderSelectedOption0State.current = false;
    } else {
      setIsValid(
        [...isValid].map((value, index) =>
          index === 3 && selectedOption0 === true ? true : value
        )
      );
      if (selectedFile3 && selectedOption0 === true) setSelectedFile3(null);
    }
  }, [selectedOption0]);

  let allIsValid = isValid.every((booleanValue) => booleanValue);
  let buttonClass =
    buttonStatus && allIsValid ? "primary-button" : "disabled-button";

  const refType =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  // console.log(refType)
  return (
    <div>
      <HeaderWhite />
      <div className="tipars-content-container">
        <TiparsDrawer />
        <div className="tipars-container">
          <div
            className="tipars-title-container"
            style={{
              borderBottom: "1px lightGrey solid",
            }}
          >
            <h1>TIPars</h1>
          </div>
          <div className="advanced-options-container">
            {/*<label>Advanced Options</label>*/}
          </div>
          {rows.map((a, i) => (
            <Input
              {...a}
              isValid={isValid}
              setIsValid={setIsValid}
              disabled={selectedOption0 === true && i === 3 ? true : false}
              withPastML={selectedOption0 === true}
              refType={refType}
            />
          ))}
          <div className="advanced-options">
            {dropdowns.map((a) => (
              <FormControlLabel
                required
                control={<Checkbox color="secondary" />}
                onChange={a.handleCheckBox}
                checked={a.selectedOption}
                label={a.label}
                refType={refType}
              />
            ))}
            {/* <FormControlLabel required control={<Checkbox />} label="Run MAFFT" /> */}
          </div>

          <div className="submit-button-container">
            <button
              className="secondary-button"
              style={{
                marginRight: "20px",
              }}
              onClick={handleClickEmpty}
            >
              Empty
            </button>
            <button
              className={buttonClass}
              disabled={!(buttonStatus && allIsValid)}
              onClick={handleClickOpen}
            >
              Continue
            </button>
          </div>
        </div>
        <Preview
          handleClose={handleClose}
          open={open}
          rows={rows}
          dropdowns={dropdowns}
          refType={refType}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userEmail: state.auth.email,
    token: state.auth.token,
    userId: state.auth.Id,
    userName: state.auth.name,
    refreshToken: state.auth.refresh,
    userLocation: state.auth.location,
  };
};
export default connect(mapStateToProps, {})(Tipars);
